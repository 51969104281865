import { ScrollContainer } from 'react-nice-scroll'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import TransportItem from './TransportItem'

function ManualSelection({ vehicleData, handleChooseTransport }) {
  return (
    <TableContainer>
      <ScrollContainer>
        <Box maxHeight="600px">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="40px"></TableCell>
                <TableCell sx={{ color: 'grey' }}>Автомобиль</TableCell>
                <TableCell sx={{ color: 'grey' }}>Ближайшие задания</TableCell>
                <TableCell sx={{ color: 'grey' }} align="center">
                  Статус
                </TableCell>
                <TableCell sx={{ color: 'grey' }}>Примечание</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {vehicleData?.data?.map((transport) => (
                <TransportItem
                  key={transport.id}
                  transport={transport}
                  handleChooseTransport={handleChooseTransport}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </ScrollContainer>
    </TableContainer>
  )
}

export default ManualSelection

import { apiService } from '../apiService'

const tasks = {
  createTasks: (bodyData) => apiService.post('tasks', bodyData),
  getTask: (id) => apiService.get(`tasks/${id}`),

  //monitoring
  getMonitoringTasks: (bodyData) =>
    apiService.get('tasks/monitoring/list', { params: bodyData }),
}

export default tasks

import { createBrowserRouter } from 'react-router-dom'

import App from '../App'
import ErrorPage from '../pages/ErrorPage'
import LoginPage from '../pages/Login/Login'
import Applications from '../pages/Applications'
import CreateApplication from '../pages/CreateApplication'
import Drivers from '../pages/Drivers/List'
import Monitoring from '../pages/Monitoring'
import DistributePassengers from '../pages/DistributePassengers'
import ApplicationProcessing from '../pages/ApplicationProcessing'
import TransportList from '../pages/Transport/List'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Monitoring />,
      },
      {
        path: 'applications',
        element: <Applications />,
      },
      {
        path: 'drivers',
        element: <Drivers />,
      },
      {
        path: 'applications/new',
        element: <CreateApplication />,
      },
      {
        path: 'applications/:applicationId',
        element: <ApplicationProcessing />,
      },
      {
        path: 'applications/:applicationId/distribution',
        element: <DistributePassengers />,
      },
      {
        path: 'transports',
        element: <TransportList />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
])

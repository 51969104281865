import { TableBody, TableCell, TableRow, Skeleton } from '@mui/material'

const TableSkeleton = ({ rowsNum, columnsNum }) => {
  const renderSkeletonColumns = () => {
    return [...Array(columnsNum)].map((column, index) => (
      <TableCell key={index}>
        <Skeleton animation="wave" variant="text" />
      </TableCell>
    ))
  }

  const renderSkeletonRows = () => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>{renderSkeletonColumns()}</TableRow>
    ))
  }

  return <TableBody>{renderSkeletonRows()}</TableBody>
}

export default TableSkeleton

import { Stack, Typography, useTheme } from '@mui/material'

import {
  dateTimeFormat,
  shortDateFormat,
  dayMonthFormat,
} from './../../utils/dateUtils'

const MainInformation = ({ applicationData }) => {
  const { common } = useTheme()
  return (
    <Stack
      spacing={3}
      bgcolor={common.colorBgDefault}
      p="20px 24px"
      borderRadius="8px"
    >
      <Typography variant="h5">Основная информация</Typography>

      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack spacing={1.5} width="45%">
          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">ID</Typography>

            <Typography>№{applicationData?.id ?? ''}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">Дата создания</Typography>

            <Typography>
              {applicationData?.created_at
                ? `${shortDateFormat(
                    applicationData.created_at
                  )}, ${dateTimeFormat(applicationData.created_at)}`
                : ''}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">Создал заявку</Typography>

            <Typography>
              {applicationData?.source_type === 'own'
                ? `${applicationData.user_created.surname} ${applicationData.user_created.name} ${applicationData.user_created.middle_name}`
                : applicationData?.external_user_created ?? ''}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">Источник</Typography>

            <Typography>
              {applicationData?.source_type === 'own'
                ? 'Odyssey drive'
                : applicationData?.source_type ?? ''}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={1.5} width="45%">
          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">Откуда</Typography>

            <Typography>{applicationData?.from_point?.name ?? ''}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">Куда</Typography>

            <Typography>{applicationData?.to_point?.name ?? ''}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="grey">Дата поездки</Typography>

            <Typography>
              {applicationData?.action_date
                ? `${dayMonthFormat(applicationData?.action_date)}, 
                ${dateTimeFormat(applicationData?.action_date)}`
                : ''}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MainInformation

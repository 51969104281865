import React from 'react'
import { createTheme } from '@mui/material/styles'
import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const LinkBehavior = forwardRef(({ href, ...other }, ref) => (
  <RouterLink to={href} ref={ref} {...other} />
))

LinkBehavior.displayName = 'LinkBehavior'

LinkBehavior.propTypes = {
  href: PropTypes.string.isRequired,
}

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
        variant: 'contained',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#121F33',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            // borderRadius: '10px',
            '& fieldset': {
              borderColor: '#F3F4F7',
            },
            '&:hover fieldset': {
              borderColor: '#627085',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#65ADFF',
            },
            '&.Mui-error fieldset': {
              borderColor: '#FF5C5C',
            },
          },
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '100%',
            marginBottom: '-10px',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          minWidth: '150px',
          background: 'white',
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '100%',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3291FF',
      hover: '#4EA4FF',
      active: '#2A86F0',
    },
    secondary: {
      main: '#F0F5FA',
      hover: '#E7F0FA',
      active: '#DFE9F5',
    },
    error: {
      main: '#FB5858',
      hover: '#FF6B6B',
      active: '#D44C4C',
    },
    success: {
      main: '#00C59D',
      hover: '#15D1AB',
      active: '#0FB694',
    },
    warning: {
      main: '#F37451',
    },
  },
  common: {
    // Фон / Background
    colorBgDefault: '#FFFFFF',
    colorBgSecondary: '#EBEDF0',
    colorBgThird: '#F5F7FA',
    colorBgGhost: '',

    colorBgPrimary: '#3291FF',
    colorBgSuccess: '#00B490',
    colorBgWarning: '#F37451',
    colorBgAlert: '#FB4444',

    colorBgPrimaryLight: '#E8F2FF',
    colorBgSuccessLight: '#E6F5F2',
    colorBgWarningLight: '#F7EEEB',
    colorBgErrorLight: '#F5E8E8',

    colorBgAttention: '#FFF0B3',
    colorBgHeader: '#EBEDF0',
    colorBgTooltip: '#1A2C47',

    // Текст / Text
    colorTypoDefault: '#121F33',
    colorTypoSecondary: '#9DA5B2',
    colorTypoWhite: '#FFFFFF',
    colorTypoPrimary: '#3291FF',

    colorTypoSuccess: '#00A791',
    colorTypoWarning: '#FC784C',
    colorTypoAlert: '#F14646',
    colorTypoDisabled: '#C3CAD4',
  },
})

export default theme

import { TextField, InputAdornment, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const SearchInput = ({ onChange, placeholder, minWidth, sx }) => {
  return (
    <Box sx={sx}>
      <TextField
        fullWidth
        size="small"
        sx={{ minWidth: minWidth, bgcolor: '#FFFFFF', borderRadius: '4px' }}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ width: '26px', height: '26px' }} />
            </InputAdornment>
          ),
        }}
        onChange={onChange}
      />
    </Box>
  )
}

export default SearchInput

import { Stack, Typography } from '@mui/material'

import { dayMonthYearFormat, dateTimeFormat } from '../../utils/dateUtils'

const ApplicationInfo = ({ applicationData }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center" pb="20px">
      <Typography>
        {applicationData?.from_point?.name ?? ''} -{' '}
        {applicationData?.to_point?.name ?? ''}
      </Typography>

      <Typography color="lightgray">•</Typography>

      <Typography>
        {applicationData?.action_date
          ? `${dayMonthYearFormat(
              applicationData?.action_date
            )}, ${dateTimeFormat(applicationData.action_date)}`
          : ''}
      </Typography>
    </Stack>
  )
}

export default ApplicationInfo

import { useRouteError } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'

const ErrorPage = () => {
  const error = useRouteError()

  return (
    <Stack spacing={6} mt={'25vh'} textAlign="center">
      <Typography variant="h3">Упс!</Typography>

      <Typography variant="h5">
        Извините, произошла непредвиденная ошибка.
      </Typography>

      <Typography variant="h4">
        {error?.statusText || error?.message}
      </Typography>
    </Stack>
  )
}

export default ErrorPage

import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { DevTool } from '@hookform/devtools'
import {
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormHelperText,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import vehicles from '../../endpoints/vehicles'
import { queryClient } from '../../queryClient'

export function Form({
  transport,
  toggleDrawer,
  marks,
  appointmentTypes,
  closeDrawer,
  drivers,
}) {
  const isEditMode = transport !== 'new'

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm({
    defaultValues: {
      mark_id: transport?.mark?.id || '',
      model_id: transport?.model?.id || '',
      appointment_type_id: transport?.appointment_type?.id || '',
      current_driver_id: transport?.current_driver?.id || '',
      name: transport?.name || '',
      vin: transport?.vin || '',
      state_number: transport?.state_number || '',
      year: transport?.year || '',
      seat_count: transport?.seat_count || '',
    },
  })

  const mark_id = watch('mark_id')

  const models = useMemo(() => {
    if (mark_id) {
      const selectedMark = marks.find((mark) => mark.id === mark_id)
      return selectedMark ? selectedMark.models || [] : []
    } else {
      return []
    }
  }, [mark_id, marks])

  const { mutate, isLoading } = useMutation(
    (data) =>
      isEditMode ? vehicles.updateVehicle(data) : vehicles.createVehicle(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vehicle')
        closeDrawer()
      },
      onError: () => {
        alert('Ошибка')
      },
    }
  )

  const onSubmit = (data) => {
    if (isEditMode) {
      mutate({
        ...data,
        id: transport.id,
        seat_count: Number(data.seat_count),
        year: Number(data.year),
      })
    } else {
      mutate({
        ...data,
        seat_count: Number(data.seat_count),
        year: Number(data.year),
      })
    }
  }

  return (
    <Stack width={650} spacing={6} p={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">
          {isEditMode ? 'Редактирование транспорта' : 'Создание транспорта'}
        </Typography>

        <IconButton aria-label="close" onClick={toggleDrawer(false, null)}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl>
            <InputLabel sx={errors.mark_id && { color: 'red' }}>
              Марки
            </InputLabel>

            <Controller
              control={control}
              name="mark_id"
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Select {...field} label="Марка" error={errors.mark_id}>
                  <MenuItem value={''}>Выберите марку</MenuItem>

                  {marks.map((mark) => (
                    <MenuItem key={mark?.id} value={mark?.id}>
                      {mark?.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FormHelperText error={errors.mark_id}>
              {errors.mark_id?.message}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel sx={errors.model_id && { color: 'red' }}>
              Модель
            </InputLabel>

            <Controller
              control={control}
              name="model_id"
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Select {...field} label="Модель" error={errors.model_id}>
                  {models &&
                    models?.map((model) => (
                      <MenuItem key={model?.id} value={model?.id}>
                        {model?.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />

            <FormHelperText error={errors.model_id}>
              {errors.model_id?.message}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel sx={errors.appointment_type_id && { color: 'red' }}>
              Тип назначении
            </InputLabel>

            <Controller
              control={control}
              name="appointment_type_id"
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Тип назначении"
                  error={errors.appointment_type_id}
                >
                  <MenuItem value={''}>Все</MenuItem>

                  {appointmentTypes?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FormHelperText error={errors.appointment_type_id}>
              {errors.appointment_type_id?.message}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel sx={errors.current_driver_id && { color: 'red' }}>
              Текущий водитель
            </InputLabel>

            <Controller
              control={control}
              name="current_driver_id"
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Текущий водитель"
                  error={errors.current_driver_id}
                >
                  {drivers?.map((item) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.surname} {item?.name} {item?.middle_name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />

            <FormHelperText error={errors.current_driver_id}>
              {errors.current_driver_id?.message}
            </FormHelperText>
          </FormControl>

          <TextField
            inputProps={{
              ...register('name', {
                required: 'Обязательное поле',
                minLength: { value: 3, message: 'Минимальная длина 3 символа' },
                maxLength: {
                  value: 50,
                  message: 'Минимальная длина 50 символов',
                },
              }),
            }}
            label="Название"
            error={errors.name}
            helperText={errors.name?.message}
          />

          <TextField
            inputProps={{
              ...register('vin', { required: 'Обязательное поле' }),
            }}
            label="VIN код"
            error={errors.vin}
            helperText={errors.vin?.message}
          />

          <TextField
            {...register('state_number', {
              required: 'Обязательное поле',
            })}
            label="Номер машины"
            error={errors.state_number}
            helperText={errors.state_number?.message}
          />

          <TextField
            inputProps={{
              ...register('year', {
                required: 'Обязательное поле',
                min: {
                  value: 1990,
                  message: 'Год должен быть больше 1990',
                },
                max: {
                  value: new Date().getFullYear(),
                  message: `Год должен быть меньше ${new Date().getFullYear()}`,
                },
                minLength: {
                  value: 4,
                  message: 'Минимальная длина 4 символа',
                },
                maxLength: {
                  value: 4,
                  message: 'Максимальная длина 4 символа',
                },
              }),
            }}
            type="number"
            label="Год"
            error={errors.year}
            helperText={errors.year?.message}
          />

          <TextField
            inputProps={{
              ...register('seat_count', {
                required: 'Обязательное поле',
                min: {
                  value: 4,
                  message: 'Количество мест должно быть больше 4',
                },
                max: {
                  value: 100,
                  message: 'Количество мест должно быть меньше 100',
                },
              }),
            }}
            type="number"
            label="Количество мест"
            error={errors.seat_count}
            helperText={errors.seat_count?.message}
          />

          <Button type="submit" disabled={isLoading}>
            {isEditMode ? 'Редактировать' : 'Создать'}
          </Button>
        </Stack>
      </form>
      <DevTool control={control} />
    </Stack>
  )
}

import { useEffect } from 'react'
import {
  IconButton,
  Typography,
  Dialog,
  Button,
  Slider,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { formatDate, dayMonthYearTimeFormat } from '../../utils/dateUtils'

const TimeSliderDialog = ({
  open,
  handleToggleTimeSliderDialog,
  handleNext,
  applicationData: { action_date, planning_start_time, planning_end_time },
  setOpenedAlreadySlider,
  value,
  setValue,
}) => {
  const d = formatDate(action_date)

  useEffect(() => {
    const d = formatDate(action_date)
    const s = formatDate(planning_start_time)
    const e = formatDate(planning_end_time)

    const sInd = s.diff(d, 'hours').hours
    const eInd = e.diff(d, 'hours').hours
    setValue([sInd, eInd])
  }, [action_date, planning_start_time, planning_end_time, setValue])

  return (
    <Dialog
      open={open}
      onClose={() => handleToggleTimeSliderDialog(false)}
      maxWidth="sm"
      fullWidth={true}
    >
      <IconButton
        onClick={() => handleToggleTimeSliderDialog(false)}
        sx={{
          position: 'absolute',
          top: '24px',
          right: '24px',
          width: '32px',
          height: '32px',
        }}
      >
        <HighlightOffIcon sx={{ width: '32px', height: '32px' }} />
      </IconButton>

      <DialogTitle>Время на задание</DialogTitle>

      <DialogContent>
        <Typography mb={6}>
          {dayMonthYearTimeFormat(d.plus({ hours: value[0] + 6 }))} -{' '}
          {dayMonthYearTimeFormat(d.plus({ hours: value[1] + 6 }))}
        </Typography>

        <Slider
          min={-10}
          max={10}
          value={value}
          step={0.5}
          onChange={(event) => setValue(event.target.value)}
          valueLabelDisplay={'on'}
          valueLabelFormat={(value) => {
            return d.plus({ hours: value }).toFormat('HH:mm')
          }}
        />
      </DialogContent>

      <DialogActions sx={{ m: '0px 14px 14px 14px' }}>
        <Button
          onClick={() => {
            handleNext()
            handleToggleTimeSliderDialog(false)
            setOpenedAlreadySlider(true)
          }}
        >
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimeSliderDialog

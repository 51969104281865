import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Typography,
  Stack,
  Slider,
  Button,
  MenuItem,
  Select,
  LinearProgress,
  ButtonGroup,
} from '@mui/material'

import vehicles from '../../../endpoints/vehicles'
import { formatDate, shortDateFormat } from '../../../utils/dateUtils'
import ManualSelection from './ManualSelection'
import AutoSelection from './AutoSelection'

function ChooseTransport({
  selectedPassengers,
  actionDate,
  value,
  setValue,
  setSelectedTransports,
  selectedTransports,
  handleNextStep,
  handleStepBack,
}) {
  const { applicationId } = useParams()

  const d = formatDate(actionDate)

  const [isUpdate, setIsUpdate] = useState(false)

  const [currentTab, setCurrentTab] = useState('auto')

  const [recommendationType, setRecommendationType] = useState(
    'economy_of_resource'
  )

  const {
    data: vehicleData,
    isLoading: isvehicleLoading,
    isError: isvehicleError,
  } = useQuery(
    [
      `vehicle/actions/${currentTab === 'auto' ? 'combinations' : 'free'}`,
      applicationId,
      selectedPassengers,
      isUpdate,
      recommendationType,
      currentTab,
    ],
    () =>
      vehicles.getActions({
        application_id: applicationId,
        passenger_ids: selectedPassengers.map((item) => item.id),
        start_time: d.plus({ hours: value[0] }),
        end_time: d.plus({ hours: value[1] }),
        recommendation_type: recommendationType,
        type: currentTab === 'auto' ? 'combinations' : 'free',
      }),
    {
      retry: 2,
      cacheTime: 0,
    }
  )

  const handleChooseTransport = (transport, event) => {
    if (event.target.checked) {
      setSelectedTransports([...selectedTransports, transport])
    } else {
      setSelectedTransports(
        selectedTransports.filter((item) => item.id !== transport.id)
      )
    }
  }

  const handleChooseVariant = (selectedVariant) => {
    setSelectedTransports(selectedVariant)
  }

  const handleContinue = () => {
    handleNextStep()
  }

  const handleBack = () => {
    handleStepBack()
  }

  if (isvehicleLoading) {
    return <LinearProgress />
  }

  if (isvehicleError) {
    alert('Ошибка при загрузке данных')
    handleBack()
  }

  return (
    <Stack spacing={3} bgcolor="#FFFFFF" p="24px 16px" borderRadius={1}>
      <Typography variant="h5">Выберите транспорт</Typography>

      <ButtonGroup>
        <Button
          onClick={() => setCurrentTab('auto')}
          color={currentTab === 'auto' ? 'primary' : 'secondary'}
        >
          Авто-подбор
        </Button>

        <Button
          onClick={() => setCurrentTab('manual')}
          color={currentTab === 'manual' ? 'primary' : 'secondary'}
        >
          Ручной подбор
        </Button>
      </ButtonGroup>

      <Stack direction="row" alignItems="end">
        <Stack sx={{ mr: 'auto', width: '200px' }}>
          <Typography sx={{ opacity: 0.7, mb: 2 }}>Рекомендации</Typography>

          <Select
            value={recommendationType}
            onChange={(event) => setRecommendationType(event.target.value)}
          >
            <MenuItem value="economy_of_resource">Экономия ресурсов</MenuItem>

            <MenuItem value="passenger_comfort">Комфорт пассажиров</MenuItem>
          </Select>
        </Stack>

        <Stack spacing={5} mr={2}>
          <Typography sx={{ opacity: 0.7 }}>
            Время задании: {shortDateFormat(d.plus({ hours: value[0] + 6 }))} -{' '}
            {shortDateFormat(d.plus({ hours: value[1] + 6 }))}
          </Typography>

          <Slider
            min={-10}
            max={10}
            value={value}
            step={0.5}
            onChange={(event, newValue) => setValue(newValue)}
            valueLabelDisplay={'on'}
            valueLabelFormat={(value) => {
              return d.plus({ hours: value }).toFormat('HH:mm')
            }}
            sx={{ width: '300px' }}
          />
        </Stack>

        <Button onClick={() => setIsUpdate(!isUpdate)}>Обновить</Button>
      </Stack>

      {currentTab === 'auto' ? (
        <AutoSelection
          vehicleData={vehicleData}
          handleChooseVariant={handleChooseVariant}
          handleContinue={handleContinue}
        />
      ) : (
        <ManualSelection
          vehicleData={vehicleData}
          handleChooseTransport={handleChooseTransport}
        />
      )}

      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Button fullWidth color="secondary" onClick={handleBack}>
          Назад
        </Button>

        <Button
          fullWidth
          onClick={handleContinue}
          disabled={selectedTransports.length === 0}
        >
          Продолжить
        </Button>
      </Stack>
    </Stack>
  )
}

export default ChooseTransport

import { DateTime } from 'luxon';

export const formatDate = (date) => {
  return DateTime.fromISO(date)
    .minus({ hours: 6 })
    // .toFormat('yyyy-MM-dd HH:mm:ss')
}

export const dayMonthFormat = (date) => {
  return DateTime.fromISO(date)
    .minus({hours: 6})
    .setLocale('ru')
    .toLocaleString({ month: 'long', day: 'numeric' })
}

export const dateTimeFormat = (date) => {
  return DateTime.fromISO(date)
    .minus({ hours: 6 })
    .toLocaleString(DateTime.TIME_24_SIMPLE)
}

export const shortDateFormat = (date) => {
  return DateTime.fromISO(date)
    .minus({ hours: 6 })
    .toLocaleString(DateTime.DATE_SHORT)
}

export const dayMonthYearFormat = (date) => {
  return DateTime.fromISO(date)
    .minus({ hours: 6 })
    .toLocaleString(DateTime.DATE_MED)
}

export const dayMonthYearTimeFormat = (date) => {
  return DateTime.fromISO(date)
    .minus({ hours: 6 })
    .toLocaleString(DateTime.DATETIME_MED)
}

export const weekDayMonthFormat = (date) => {
  return DateTime.fromISO(date)
    .minus({ hours: 6 })
    .setLocale('ru')
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    .slice(0, -7)
}
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'
import { TextField, Button, Stack, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import auth from '../../endpoints/auth'
import account from '../../endpoints/account'
import { store } from '../../store'

const Login = () => {
  const { common } = useTheme()

  const navigate = useNavigate()

  const { isAuthenticated } = useSnapshot(store.auth)

  const [isAccountQueryEnabled, setAccountQueryEnabled] = useState(true)

  const {
    register,
    setFocus,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const { isLoading: isAccountLoading } = useQuery(
    'account',
    () => account.getAccount(),
    {
      onSuccess: (data) => {
        if (data) {
          store.auth.isAuthenticated = true
          store.user = data?.data
        }
      },
      retry: false,
      enabled: isAccountQueryEnabled,
    }
  )

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  useEffect(() => {
    if (!isAccountLoading && isAuthenticated) {
      navigate('/')
    }
    setAccountQueryEnabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountLoading])

  const { mutate, isLoading: authLoading } = useMutation(
    (data) => auth.login(data),
    {
      onSuccess: (data) => {
        localStorage.setItem('token', data.data.access_token)
        store.auth.isAuthenticated = true
        store.user = data.data.user
        navigate('/applications')
      },

      onError: (err) => {
        err.response.data.status === 500
          ? alert('Ошибка сервера, попробуйте позже')
          : alert(err.response?.data?.message)
      },
    }
  )

  const onSubmit = (data) => {
    mutate(data)
  }

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      bgcolor={common.colorBgSecondary}
      sx={{ minHeight: '100vh' }}
      p="40px 32px 32px 32px"
    >
      <Typography variant={'h4'} mb={6}>
        Odyssey Drive
      </Typography>

      {isAccountLoading ? (
        'Загрузка...'
      ) : (
        <Stack
          bgcolor={'#FFFFFF'}
          width={400}
          p={'24px 20px'}
          borderRadius="12px"
        >
          <Typography variant={'h5'} align="center" mb={4}>
            Вход в аккаунт
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Электронная почта"
              fullWidth={true}
              type="email"
              error={errors.email?.message}
              helperText={errors.email?.message}
              {...register('email', {
                required: 'Логин не должен быть пустым',
              })}
            />

            <TextField
              label="Пароль"
              fullWidth={true}
              type="password"
              sx={{ mt: 5 }}
              error={errors.password?.message}
              helperText={errors.password?.message}
              {...register('password', {
                required: 'Пароль не должен быть пустым',
              })}
            />

            <Button
              fullWidth={true}
              type="submit"
              sx={{ borderRadius: '5px', mt: 5 }}
              disabled={authLoading}
            >
              {authLoading ? 'Входим в систему...' : 'Отправить'}
            </Button>
          </form>
        </Stack>
      )}

      <Typography color={common.colorTypoSecondary} mt={6}>
        Odyssey Drive Demo MVP ver. 1.0
      </Typography>
    </Stack>
  )
}

export default Login

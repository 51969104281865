import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'
import moment from 'moment'
import { ScrollContainer } from 'react-nice-scroll'
import 'moment/locale/ru'
import {
  Avatar,
  Stack,
  TextField,
  Typography,
  Button,
  useTheme,
} from '@mui/material'

import { actionHistory } from '../../endpoints/actionHistory'
import { queryClient } from '../../queryClient'

const ActionLog = ({ applicationId }) => {
  const { common } = useTheme()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const {
    data: actionHistoryData,
    isLoading: actionHistoryLoading,
    isError: actionHistoryError,
  } = useQuery(
    `action-histories/by-application/${applicationId}`,
    () => actionHistory.getActionHistory(applicationId),
    { enabled: !!applicationId }
  )

  const { mutate, isLoading: createCommentLoading } = useMutation(
    (data) => actionHistory.createComment(data),
    {
      onSuccess: () => {
        alert('Комментарий успешно отправлен')
        queryClient.invalidateQueries(
          `action-histories/by-application/${applicationId}`
        )
        reset()
      },
      onError: () => {
        alert('Ошибка отправки комментария')
      },
    }
  )

  const onSubmit = (data) => {
    mutate({ ...data, application_id: Number(applicationId) })
  }

  return (
    <Stack
      spacing={4}
      bgcolor={common.colorBgDefault}
      p="24px"
      borderRadius="8px"
    >
      <Typography variant="h5">Журнал действий</Typography>

      <ScrollContainer>
        <Stack spacing={2.5} maxHeight="300px">
          {actionHistoryLoading ? (
            <Typography variant="h5">Загрузка...</Typography>
          ) : actionHistoryError ? (
            <Typography variant="h5">Ошибка загрузки данных</Typography>
          ) : (
            actionHistoryData?.data?.map((item) => {
              const date = moment(item.created_at)
                .subtract(6, 'hours')
                .calendar()
              return (
                <Stack
                  key={item.id}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Avatar />

                  <Stack>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />

                    <Typography variant="subtitle2" sx={{ opacity: 0.5 }}>
                      {date}
                    </Typography>
                  </Stack>
                </Stack>
              )
            })
          )}
        </Stack>
      </ScrollContainer>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography variant="h6">Написать комментарий</Typography>

          <TextField
            multiline
            minRows={3}
            maxRows={5}
            placeholder="Ваш комментарий..."
            {...register('comment', {
              required: 'Это поле обязательное',
            })}
            error={!!errors.comment}
            helperText={errors.comment?.message}
          />

          <Stack direction="row">
            <Button
              sx={{ marginLeft: 'auto' }}
              type="submit"
              disabled={createCommentLoading}
            >
              {createCommentLoading ? 'Отправка...' : 'Отправить'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}

export default ActionLog

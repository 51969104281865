import { useState, memo, useEffect, useRef } from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import groupBy from 'lodash/groupBy'
import {
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Dialog,
  Backdrop,
  useTheme,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import applications from '../../endpoints/applications'
import { queryClient } from '../../queryClient'

const AddPassengersModal = memo(({ open, handleToggleAddPassengersModal }) => {
  const { common } = useTheme()

  const { handleSubmit } = useForm()

  const { applicationId } = useParams()

  const [excelLoading, setExcelLoading] = useState(false)

  const fullNameInputRef = useRef(null)

  const autoscrollRef = useRef(null)

  const [passengers, setPassengers] = useState([
    { full_name: '', iin: '', position: '' },
  ])

  const [isEmptyPassengers, setIsEmptyPassengers] = useState(true)

  const {
    mutate: updateApplicationMutate,
    isLoading: isUpdatePassengersLoading,
  } = useMutation(
    (data) =>
      applications.updateApplication(data.applicationId, {
        passengers: data.passengers,
      }),
    {
      onSuccess: (data) => {
        alert(
          `Пассажиры успешно добавлены. Количества пассажиров: ${passengers?.length}`
        )
        handleToggleAddPassengersModal(false)
        queryClient.invalidateQueries(`applications/${data?.data?.id}`)
        queryClient.invalidateQueries(
          `action-histories/by-application/${data?.data?.id}`
        )
      },
      onError: () => {
        alert('Ошибка при добавлении пассажиров')
      },
      onSettled: () => {
        setPassengers([{ full_name: '', iin: '', position: '' }])
        setIsEmptyPassengers(true)
      },
    }
  )

  const handleAddPassenger = () => {
    setPassengers([...passengers, { full_name: '', iin: '', position: '' }])

    setTimeout(() => {
      fullNameInputRef.current.focus()
      if (autoscrollRef.current) {
        const scrollContainer = autoscrollRef.current
        scrollContainer.scrollTo({
          behavior: 'smooth',
          top: scrollContainer.scrollHeight,
        })
      }
    }, 100)
  }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target
    const updatedPassengers = [...passengers]
    updatedPassengers[index][name] = value
    setPassengers(updatedPassengers)
  }

  useEffect(() => {
    if (
      passengers.length === 1 &&
      isEqual(passengers[0], { full_name: '', iin: '', position: '' })
    ) {
      setIsEmptyPassengers(true)
    } else {
      setIsEmptyPassengers(false)
    }
  }, [passengers])

  const handleRemovePassenger = (index) => {
    setPassengers((prevPassengers) =>
      prevPassengers.filter((_, passengerIndex) => passengerIndex !== index)
    )
    if (passengers.length === 1) {
      setPassengers([{ full_name: '', iin: '', position: '' }])
    }
  }

  const handleUpload = async (event) => {
    setExcelLoading(true)
    try {
      const file = event.target.files?.[0]
      const formData = new FormData()
      formData.append('file', file)
      const response = await applications.uploadDataFromExcel(formData)
      setPassengers([...passengers, ...response.data])
    } catch (error) {
      alert('Ошибка при загрузке файла')
    } finally {
      setExcelLoading(false)
    }
  }

  const onSubmit = () => {
    const hasEmptyData = passengers.some(
      (passenger) =>
        passenger.full_name.trim() === '' || passenger.iin.trim() === ''
    )

    if (hasEmptyData) {
      alert('Пожалуйста, заполните все поля ФИО и ИИН для каждого пассажира')
      return
    }

    const groupedByIIN = groupBy(passengers, 'iin')

    const duplicateIINs = Object.values(groupedByIIN).filter(
      (group) => group.length > 1
    )

    if (duplicateIINs.length > 0) {
      let alertMessage = 'Найдены пассажиры с одинаковыми ИИН!!!\n'

      duplicateIINs.forEach((group) => {
        const duplicateFullNames = group.map((passenger) => passenger.full_name)
        const passengerIndices = group.map(
          (passenger) => passengers.indexOf(passenger) + 1
        )

        const fullNameList = duplicateFullNames
          .map((fullName, index) => `${passengerIndices[index]}. ${fullName}`)
          .join(', ')

        alertMessage += `ИИН: ${group[0].iin}\n${fullNameList}\n`
      })

      alert(alertMessage)
      return
    }

    updateApplicationMutate({ applicationId, passengers })
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleToggleAddPassengersModal(false)}
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      ref={autoscrollRef}
    >
      <IconButton
        onClick={() => handleToggleAddPassengersModal(false)}
        sx={{
          position: 'absolute',
          top: '24px',
          right: '24px',
          width: '32px',
          height: '32px',
        }}
      >
        <HighlightOffIcon sx={{ width: '32px', height: '32px' }} />
      </IconButton>

      <DialogTitle>Пассажиры</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            p="20px 50px 10px 0px"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              color={common.colorTypoSecondary}
              width={35}
            >
              №
            </Typography>

            <Typography
              width="50%"
              variant="subtitle1"
              color={common.colorTypoSecondary}
            >
              ФИО
            </Typography>

            <Typography
              width="50%"
              variant="subtitle1"
              color={common.colorTypoSecondary}
            >
              ИИН
            </Typography>
          </Stack>

          <Stack spacing={2}>
            {passengers.map((passenger, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography width={60}>{index + 1}</Typography>

                <TextField
                  fullWidth
                  size="small"
                  name="full_name"
                  value={passenger.full_name}
                  onChange={(event) => handleInputChange(index, event)}
                  inputRef={
                    index === passengers.length - 1 ? fullNameInputRef : null
                  }
                />

                <TextField
                  fullWidth
                  size="small"
                  name="iin"
                  value={passenger.iin}
                  onChange={(event) => handleInputChange(index, event)}
                />

                <DeleteOutlineIcon
                  onClick={() => handleRemovePassenger(index)}
                  sx={{
                    cursor: isEmptyPassengers ? 'not-allowed' : 'pointer',
                    color: isEmptyPassengers ? 'grey' : 'red',
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ m: '0px 14px 14px 0px' }}>
          <Button onClick={handleAddPassenger} sx={{ mr: 1 }}>
            Добавить пассажира
          </Button>

          <Box sx={{ position: 'relative' }}>
            <Button component="label" disabled={excelLoading}>
              Загрузить список с Excel
              <input
                hidden
                accept="excel/*"
                type="file"
                onChange={handleUpload}
              />
            </Button>

            {excelLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'green',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>

          <Button type="submit" disabled={isUpdatePassengersLoading}>
            Сохранить
          </Button>
        </DialogActions>
        <Backdrop
          open={isUpdatePassengersLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </Dialog>
  )
})

export default AddPassengersModal

import { useState, useMemo } from 'react'
import { ScrollContainer } from 'react-nice-scroll'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import VariantItem from './VariantItem'
import DialogWindow from './DialogWindow'

function AutoSelection({ vehicleData, handleChooseVariant, handleContinue }) {
  const [openDialog, setOpenDialog] = useState(false)

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(null)

  const handleToggleDialog = (modalState, index) => {
    setOpenDialog(modalState)
    setSelectedVariantIndex(index)
  }

  const selectedVariant = useMemo(
    () => vehicleData?.data[selectedVariantIndex]?.vehicles,
    [vehicleData, selectedVariantIndex]
  )

  return (
    <TableContainer>
      <ScrollContainer>
        <Box maxHeight="600px">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: 'grey' }}>Вариант(ы)</TableCell>
                <TableCell sx={{ color: 'grey' }}>Транспорт</TableCell>
                <TableCell sx={{ color: 'grey' }}>Заполняемость</TableCell>
                <TableCell sx={{ color: 'grey' }}>Примечание</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {vehicleData?.data?.map((variant, index) => (
                <VariantItem
                  key={index}
                  variant={variant}
                  index={index}
                  openDialog={openDialog}
                  handleToggleDialog={handleToggleDialog}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </ScrollContainer>

      <DialogWindow
        open={openDialog}
        handleToggleDialog={handleToggleDialog}
        selectedVariant={selectedVariant}
        selectedVariantIndex={selectedVariantIndex}
        handleChooseVariant={handleChooseVariant}
        handleContinue={handleContinue}
      />
    </TableContainer>
  )
}

export default AutoSelection

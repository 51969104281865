import { Stack, Typography, Checkbox, TableRow, TableCell } from '@mui/material'
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'

import TaskItem from './TaskItem'

const TransportItem = ({ transport, handleChooseTransport }) => {
  const status = !transport?.is_free
    ? 'Занят'
    : transport?.suitable_task !== null
    ? 'В текущее'
    : 'Свободен'

  const statusColor = !transport?.is_free
    ? '#F14646'
    : transport?.suitable_task !== null
    ? '#3291FF'
    : '#00A791  '

  const statusBgColor = !transport?.is_free
    ? '#F5E8E8'
    : transport?.suitable_task !== null
    ? '#E8F2FF'
    : '#E6F5F2'

  return (
    <TableRow>
      <TableCell component="th" width="40px">
        <Checkbox
          sx={{ width: '20px', height: '20px' }}
          disabled={!transport?.is_free}
          onChange={(event) => {
            handleChooseTransport(transport, event)
          }}
        />
      </TableCell>

      <TableCell>
        <Stack>
          <Typography variant="subtitle2">
            {transport?.mark?.name} {transport?.model?.name}
          </Typography>

          <Typography color="gray">
            {transport?.current_driver?.surname}{' '}
            {transport?.current_driver?.name?.slice(0, 1)}
            {'.'}
            {transport?.current_driver?.middle_name?.slice(0, 1)}
            {'.'}
          </Typography>

          <Typography color="gray" variant="body2">
            {transport?.state_number}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack spacing={1}>
          {transport?.tasks?.map((task) => (
            <TaskItem key={task.id} transport={transport} task={task} />
          ))}
        </Stack>
      </TableCell>

      <TableCell align="center" width="130px">
        <Typography
          bgcolor={statusBgColor}
          color={statusColor}
          display="inline"
          p="4px 8px"
          borderRadius="5px"
        >
          {status}
        </Typography>

        <Typography mt={1}>{transport?.free_seat_count} мест</Typography>
      </TableCell>

      <TableCell>
        {transport.notes.map((item, index) => {
          return (
            <Stack direction="row" spacing={1} mb={0.5} key={index}>
              <AnnouncementOutlinedIcon sx={{ color: '#FC784C' }} />
              <Typography color="#121F33">{item.message}</Typography>
            </Stack>
          )
        })}
      </TableCell>
    </TableRow>
  )
}

export default TransportItem

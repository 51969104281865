import { Autocomplete, TextField } from '@mui/material'

function AutocompleteOfList({
  point,
  fromPointId,
  toPointId,
  setFromPointId,
  setToPointId,
  allPointsOptions,
  allPointsLoading,
  allPointsError,
}) {
  return (
    <Autocomplete
      fullWidth
      size="small"
      disablePortal
      options={allPointsOptions}
      getOptionLabel={(option) => option.label ?? ''}
      isOptionEqualToValue={(option, value) => option.id === value.id ?? ''}
      sx={{
        minWidth: '150px',
        maxWidth: '150px',
        mr: 1.5,
      }}
      value={point.label === 'Откуда' ? fromPointId : toPointId}
      onChange={(event, newValue) => {
        point.label === 'Откуда'
          ? setFromPointId(newValue)
          : setToPointId(newValue)
      }}
      isloading={allPointsLoading.toString()}
      error={allPointsError.toString()}
      disabled={allPointsLoading || allPointsError}
      renderInput={(params) => (
        <TextField
          {...params}
          label={point.label}
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '4px',
            ...(allPointsLoading && { opacity: 0.5 }),
            ...(allPointsError && {
              borderColor: 'red',
            }),
          }}
          helperText={
            allPointsLoading
              ? 'Загрузка данных...'
              : allPointsError
              ? 'Ошибка ...'
              : ''
          }
          error={allPointsError}
        />
      )}
    />
  )
}

export default AutocompleteOfList

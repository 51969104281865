import { Stack, Typography } from '@mui/material'

function TaskItem({ transport, task }) {
  const bgcolor =
    task?.id === transport?.suitable_task?.id ? '#E8F2FF' : '#F5F7FA'

  const color =
    task?.id === transport?.suitable_task?.id ? '#3291FF' : '#121F33'
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      bgcolor={bgcolor}
      p="6px 8px"
      borderRadius="6px"
    >
      <Typography variant="subtitle2" color={color}>
        {task?.from_point?.name.length >= 13
          ? task?.from_point?.name.slice(0, 13) + '...'
          : task?.from_point?.name}{' '}
        -{' '}
        {task?.to_point?.name.length >= 13
          ? task?.to_point?.name.slice(0, 13) + '...'
          : task?.to_point?.name}
      </Typography>

      <Typography variant="subtitle2" color={color}>
        {task?.action_start_time.slice(11, 16)} -{' '}
        {task?.action_end_time.slice(11, 16)}
      </Typography>
    </Stack>
  )
}

export default TaskItem

import { apiService } from '../apiService'

const applications = {
  getApplications: (bodyData) =>
    apiService.get(`applications/many/${bodyData.view_type}`, { params: bodyData }),
  createApplication: (bodyData) => apiService.post('applications', bodyData),
  getApplicationById: (applicationId) =>
    apiService.get(`applications/${applicationId}`),

  // Processing applications
  updateApplication: (applicationId, bodyData) =>
    apiService.post(`application-passengers/${applicationId}`, bodyData),
  deletePassengerFromApplication: (applicationId, passengerId) =>
    apiService.delete(`application-passengers/${applicationId}/${passengerId}`),
  rejectApplication: (applicationId) =>
    apiService.post(`/applications/action/reject/${applicationId}`),

  uploadDataFromExcel: (bodyData) => apiService.post('files/excel', bodyData),
}

export default applications

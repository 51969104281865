import { QueryClient } from 'react-query'
import { apiService } from './apiService'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url] }) => {
        if (typeof url === 'string') {
          const { data } = await apiService.get(
            `${process.env.REACT_APP_DEV_MODE}/${url.toLowerCase()}`
          )
          return data
        }
        throw new Error('Invalid QueryKey')
      },
      refetchOnWindowFocus: true,
      staleTime: 10000,
      retry: (failureCount) => failureCount < 4,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
    },
  },
})

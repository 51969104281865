import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
} from '@mui/material'

import {
  dateTimeFormat,
  dayMonthYearTimeFormat,
} from '../../../utils/dateUtils'

const Tasks = ({ tasks }) => {
  return (
    <Box my={2} sx={{ maxHeight: '500px', overflowY: 'auto' }}>
      {tasks.length === 0 ? (
        <Typography variant="h5" textAlign="center" mb={6}>
          Нет заданий
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead sx={{ bgcolor: '#F0F0F0' }}>
              <TableRow>
                <TableCell>ID задания</TableCell>
                <TableCell>Транспорт</TableCell>
                <TableCell align="center">Время</TableCell>
                <TableCell align="center">Комментарий</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">№{task?.id}</Typography>

                    <Typography variant="body2" color="gray">
                      {dayMonthYearTimeFormat(task?.created_at)}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="subtitle2">
                      {task?.vehicle?.mark?.name} {task?.vehicle?.model?.name}{' '}
                      {task?.vehicle?.year}
                    </Typography>

                    <Typography variant="body2" color="gray">
                      {task?.vehicle?.state_number}, {task?.driver?.surname}{' '}
                      {task?.driver?.name.slice(0, 1)}.{' '}
                      {task?.driver?.middle_name &&
                        task?.driver?.middle_name.slice(0, 1)}
                      .
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      {dateTimeFormat(task?.action_start_time)} -{' '}
                      {dateTimeFormat(task?.action_end_time)}
                    </Typography>

                    <Typography variant="body2" color="gray">
                      Кол. пассажиров:{' '}
                      {task?.passenger_application_pairs?.length}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      {task?.notes?.map((item) => item.message)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default Tasks

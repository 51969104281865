import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

export function Passengers({ passengers }) {
  return (
    <Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ opacity: 0.5 }}>
              <TableCell>Место</TableCell>
              <TableCell>ФИО</TableCell>
              <TableCell>Заявка</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengers.map((passenger, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="passenger">
                    {passenger?.seat_number && '№' + passenger?.seat_number}
                  </TableCell>

                  <TableCell>{passenger?.passenger?.full_name}</TableCell>

                  <TableCell>№{passenger?.application?.id}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

import { useState } from 'react'
import { Stack, Button, Typography, ButtonGroup } from '@mui/material'

import List from './List'

const Applications = () => {
  const [currentTab, setCurrentTab] = useState('active')

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Заявки</Typography>

        <Button href="/applications/new">Создать заявку</Button>
      </Stack>

      <ButtonGroup>
        <Button
          onClick={() => setCurrentTab('active')}
          color={currentTab === 'active' ? 'primary' : 'secondary'}
        >
          Активные
        </Button>

        <Button
          onClick={() => setCurrentTab('archive')}
          color={currentTab === 'archive' ? 'primary' : 'secondary'}
        >
          Архивные
        </Button>
      </ButtonGroup>

      <List currentTab={currentTab} />
    </Stack>
  )
}

export default Applications

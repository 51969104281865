import { Stack, Typography, TableCell, TableRow, Button } from '@mui/material'
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'

function VariantItem({ variant, index, handleToggleDialog }) {
  return (
    <TableRow>
      <TableCell component="th">Вариант {index + 1}</TableCell>

      <TableCell>
        <Typography variant="subtitle2">
          {variant.vehicles_count} транспорт(а/ов)
        </Typography>

        <Typography color="gray">{variant.vehicles_info}</Typography>
      </TableCell>

      <TableCell>
        {variant.free_seats_count}/{variant.passengers_count}
      </TableCell>

      <TableCell>
        {variant.notes.map((item, index) => (
          <Stack direction="row" spacing={1} mb={0.5} key={index}>
            <AnnouncementOutlinedIcon sx={{ color: '#FC784C' }} />

            <Typography color="#121F33">{item.message}</Typography>
          </Stack>
        ))}
      </TableCell>

      <TableCell align="right">
        <Button onClick={() => handleToggleDialog(true, index)}>Выбрать</Button>
      </TableCell>
    </TableRow>
  )
}

export default VariantItem

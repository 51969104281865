import { NavLink } from 'react-router-dom'
import { ListItem, Box, Typography } from '@mui/material'

import {
  ApplicationIcon,
  DriversIcon,
  MonitoringIcon,
  TransportsIcon,
  // CalendarIcon,
  // ChartIcon,
  // ClockIcon,
  // GradesIcon,
  // MapIcon,
  // WorkScheduleIcon,
} from '../../assets/svg/Icons'

// const MainMenu = [
//   {
//     icon: <MapIcon />,
//     text: 'Карта',
//     url: '/map',
//   },
//   {
//     icon: <ClockIcon />,
//     text: 'Статистика',
//     url: '/statistics',
//   },
//   {
//     icon: <ChartIcon />,
//     text: 'Планирование',
//     url: '/planning',
//   },
// ]

const ManagementMenu = [
  {
    icon: <MonitoringIcon />,
    text: 'Мониторинг',
    url: '/',
  },
  {
    icon: <ApplicationIcon />,
    text: 'Заявки',
    url: '/applications',
  },
  // {
  //   icon: <CalendarIcon />,
  //   text: 'Рабочий календарь',
  //   url: '/work-calendar',
  // },
]

const ReferenceMenu = [
  {
    icon: <DriversIcon />,
    text: 'Водители',
    url: '/drivers',
  },
  {
    icon: <TransportsIcon />,
    text: 'Транспорт',
    url: '/transports',
  },
  // {
  //   icon: <GradesIcon />,
  //   text: 'Категории',
  //   url: '/grades',
  // },
  // {
  //   icon: <WorkScheduleIcon />,
  //   text: 'Графики',
  //   url: '/work-schedule',
  // },
]

const MenuItem = ({ icon, text, url }) => {
  return (
    <ListItem
      disablePadding
      component={NavLink}
      to={`${url}`}
      style={({ isActive }) => ({
        color: isActive ? '#000000' : '#FFFFFF',
        backgroundColor: isActive ? '#FFFFFF' : 'transparent',
        height: 40,
        paddingLeft: 24,
        fontWeight: isActive ? 500 : 300,
      })}
    >
      {text}
    </ListItem>
  )
}

const MenuSection = ({ title, children }) => {
  return (
    <Box marginBottom="20px">
      <Typography
        variant="subtitle1"
        sx={{ pl: '12px', color: '#9DA5B2', mb: '22px' }}
      >
        {title}
      </Typography>

      {children}
    </Box>
  )
}

const SideMenu = () => {
  return (
    <Box>
      {/* <MenuSection title="Основное">
        {MainMenu.map((item) => (
          <MenuItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            url={item.url}
          />
        ))}
      </MenuSection> */}

      <MenuSection title="Управление">
        {ManagementMenu.map((item) => (
          <MenuItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            url={item.url}
          />
        ))}
      </MenuSection>

      <MenuSection title="Справочники">
        {ReferenceMenu.map((item) => (
          <MenuItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            url={item.url}
          />
        ))}
      </MenuSection>
    </Box>
  )
}

export default SideMenu

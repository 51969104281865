export const extendedStatuses = [
  {
    value: 'open',
    label: 'Открытые',
    text: 'Открыта',
    color: '#3291FF',
    bgcolor: '#E8F2FF',
  },
  {
    value: 'distributed',
    label: 'Распределенные',
    text: 'Распределена',
    color: '#00A791',
    bgcolor: '#E6F5F2',
  },
  {
    value: 'on_process',
    label: 'В процессе',
    text: 'В процессе',
    color: '#121F33',
    bgcolor: '#FFF0B3',
  },
  {
    value: 'completed',
    label: 'Завершенные',
    text: 'Завершена',
    color: '#60AEA4',
    bgcolor: '#E6F5F2',
  },
  {
    value: 'rejected',
    label: 'Отмененные',
    text: 'Отменена',
    color: '#EBEDF0',
    bgcolor: '#9DA5B2',
  },
  // {
  //   value: 'rejected',
  //   label: 'Отклоненные',
  //   text: 'Отклонена',
  //   color: '#F14646',
  //   bgcolor: '#F5E8E8'
  // }
]

// export const taskStatuses = [
//   {
//     value: 'new',
//     label: 'Новые',
//     text: 'Новая',
//   },
//   {
//     value: 'active',
//     label: 'Активные',
//     text: 'Активна',
//   },
//   {
//     value: 'on_process',
//     label: 'Выполняется',
//     text: 'Выполняется',
//   },
//   {
//     value: 'completed',
//     label: 'Завершенные',
//     text: 'Завершено',
//   },
//   {
//     value: 'rejected',
//     label: 'Отклоненные',
//     text: 'Отклонена',
//   },
// ]

export const taskStatuses = [
  {
    value: 'all',
    label: 'Все',
  },
  {
    value: 'active',
    label: 'Ожидает',
    color: '#3291FF',
    bgcolor: '#E8F2FF',
  },
  {
    value: 'on_process',
    label: 'В работе',
    color: '#121F33',
    bgcolor: '#FFF0B3',
  },
  {
    value: 'completed',
    label: 'Завершено',
    color: '#00A791',
    bgcolor: '#E6F5F2',
  },
  {
    value: 'rejected',
    label: 'Отменено',
    color: '#EBEDF0',
    bgcolor: '#9DA5B2',
  },
]

export const route_points = [
  {
    label: 'Откуда',
  },
  {
    label: 'Куда',
  },
]

export const buttonData = [
  { label: 'Все', value: 'all' },
  { label: 'Распределенные', value: 'distributed' },
  { label: 'Не распределенные', value: 'notDistributed' },
]

import { Stack, Pagination, PaginationItem } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const CustomPagination = ({ count, handleChangePage, page }) => {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Pagination
        count={count}
        onChange={handleChangePage}
        page={page}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  )
}

export default CustomPagination

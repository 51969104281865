import { Divider, Stack, Typography } from '@mui/material'

import {
  dayMonthFormat,
  dateTimeFormat,
  shortDateFormat,
} from '../../../utils/dateUtils'

const Details = ({ application, status }) => {
  return (
    <Stack spacing={4}>
      <Stack spacing={1.5}>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Статус</Typography>

          <Typography
            sx={{
              backgroundColor: `${status?.bgcolor}`,
              color: `${status?.color}`,
              padding: '4px 8px',
              borderRadius: '16px',
              mb: '-4px',
            }}
          >
            {status.text ?? ''}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Откуда</Typography>

          <Typography>{application?.from_point?.name ?? ''}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Куда</Typography>

          <Typography>{application?.to_point?.name ?? ''}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Дата поездки</Typography>

          <Typography>
            {application?.action_date
              ? `${dayMonthFormat(application?.action_date)}, ${dateTimeFormat(
                  application?.action_date
                )}`
              : ''}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Кол-во пассажиров</Typography>

          <Typography>
            {application?.passengers?.length > 0
              ? application.passengers.length + ' чел'
              : 'Нет пассажиров'}
          </Typography>
        </Stack>
      </Stack>

      <Divider />

      <Stack spacing={1.5}>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">ID</Typography>

          <Typography>№{application?.id ?? ''}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Дата создания</Typography>

          <Typography>
            {application?.created_at
              ? `${shortDateFormat(application.created_at)}, ${dateTimeFormat(
                  application.created_at
                )}`
              : ''}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="grey">Создал заявку</Typography>

          <Typography>
            {application?.source_type === 'own'
              ? `${application.user_created.surname} ${application.user_created.name} ${application.user_created.middle_name}`
              : application?.external_user_created ?? ''}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography color="gray">
            {application.source_type === 'own'
              ? 'Odyssey drive'
              : 'application.source_type'}
          </Typography>

          <Typography>
            {application.source_type === 'own'
              ? `${application.user_created.surname} ${
                  application?.user_created?.name &&
                  application?.user_created?.name?.slice(0, 1) + '.'
                } ${
                  application?.user_created?.middle_name &&
                  application?.user_created?.middle_name?.slice(0, 1) + '.'
                }`
              : application?.external_user_created}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Details

import { apiService } from '../apiService'

const references = {
  //marks
  getCarMarks: () => apiService.get('references/car-mark'),

  //models
  getCarModels: (markId) => apiService.get(`references/car-model/${markId}`),

  getAppointmentTypes: () => apiService.get('references/appointment-type'),
}

export default references

import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { DateTime } from 'luxon'
import { Stack, Typography, LinearProgress } from '@mui/material'

import applications from '../../endpoints/applications'
import { extendedStatuses } from '../../enums'
import Passengers from './Passengers'
import MainInformation from './MainInformation'
import Tasks from './../Applications/ApplicationModal/Tasks'
import ActionLog from './ActionLog'

const ApplicationProcessing = () => {
  const { applicationId } = useParams()

  const navigate = useNavigate()
  const {
    data: application,
    isError: applicationError,
    isLoading: applicationLoading,
  } = useQuery(
    `applications/${applicationId}`,
    () => applications.getApplicationById(applicationId),
    { enabled: !!applicationId }
  )

  if (applicationError) {
    return 'Ошибка загрузки данных'
  }

  const isActionDatePast = application?.data?.action_date
    ? DateTime.fromISO(application.data.action_date) < DateTime.local()
    : false

  if (application?.data?.status === 'rejected' || isActionDatePast) {
    navigate('/applications')
  }

  const { passengers, tasks } = application?.data || {}

  const status = extendedStatuses.find(
    (item) => item.value === application?.data?.status
  )

  return (
    <>
      {applicationLoading ? (
        <LinearProgress />
      ) : (
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" mb={2}>
            <Typography variant="h3" marginRight={1.5}>
              Заявка №{application?.data?.id}
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                backgroundColor: `${status?.bgcolor}`,
                color: `${status?.color}`,
                padding: '6px 12px',
                borderRadius: '16px',
                display: 'inline-block',
              }}
            >
              {status?.text}
            </Typography>
          </Stack>

          <MainInformation applicationData={application?.data} />

          <Passengers passengers={passengers} />

          <Stack spacing={2} bgcolor="#FFF" borderRadius="8px">
            <Typography variant="h5" p={2.5}>
              Задания
            </Typography>

            <Tasks tasks={tasks} />
          </Stack>

          <ActionLog applicationId={applicationId} />
        </Stack>
      )}
    </>
  )
}

export default ApplicationProcessing

import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Stack,
  Typography,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Box,
  useTheme,
} from '@mui/material'
import { ScrollContainer } from 'react-nice-scroll'

import SearchInput from '../../../components/ui/SearchInput'

const SelectPassengers = ({
  applicationId,
  passengers,
  selectedPassengers,
  setSelectedPassengers,
  handleStepOrOpenTimeSlider,
}) => {
  const navigate = useNavigate()

  const { common } = useTheme()

  const [checkAllState, setCheckAllState] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (selectedPassengers.length === passengers.length) {
      setCheckAllState(true)
    } else if (
      selectedPassengers.length > 0 &&
      selectedPassengers.length < passengers.length
    ) {
      setCheckAllState(null)
    } else {
      setCheckAllState(false)
    }
  }, [selectedPassengers, passengers, checkAllState])

  const filteredPassengers = useMemo(() => {
    return passengers.filter((passenger) =>
      passenger.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [searchQuery, passengers])

  const handleChooseAll = (event) => {
    if (event.target.checked) {
      setCheckAllState(true)
      setSelectedPassengers(passengers.map((index) => index))
    } else {
      setCheckAllState(false)
      setSelectedPassengers([])
    }
  }

  const handleChooseOne = (passenger) => (event) => {
    if (event.target.checked) {
      setSelectedPassengers([...selectedPassengers, passenger])
    } else {
      setSelectedPassengers(
        selectedPassengers.filter((item) => item.id !== passenger.id)
      )
    }
  }

  const handleContinue = () => {
    handleStepOrOpenTimeSlider()
  }

  return (
    <>
      <Stack
        spacing={3}
        bgcolor={common.colorBgDefault}
        borderRadius="8px"
        py="24px"
      >
        <Stack direction="row" justifyContent="space-between" px={3}>
          <Typography variant="h5">Выбрать пассажиров</Typography>

          <SearchInput
            minWidth={250}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Поиск по ФИО"
            sx={{ width: '350px' }}
          />
        </Stack>

        {passengers?.length > 0 ? (
          <TableContainer>
            <ScrollContainer>
              <Box maxHeight="500px">
                <Table>
                  <TableHead bgcolor="#E9E9E9">
                    <TableRow sx={{ height: '40px' }}>
                      <TableCell> ФИО</TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={checkAllState === true}
                          indeterminate={checkAllState === null}
                          onChange={handleChooseAll}
                          size="small"
                          sx={{ width: '18px', height: '18px' }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredPassengers?.map((passenger) => (
                      <TableRow key={passenger.id}>
                        <TableCell component="th" scope="passenger">
                          {passenger.full_name}
                        </TableCell>

                        <TableCell align="right">
                          <Checkbox
                            checked={
                              checkAllState === true
                                ? true
                                : selectedPassengers.includes(passenger)
                            }
                            size="small"
                            onChange={handleChooseOne(passenger)}
                            sx={{ width: '18px', height: '18px' }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </ScrollContainer>
          </TableContainer>
        ) : (
          <Typography variant="h5" textAlign="center" py={6}>
            Нет пассажиров
          </Typography>
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Button
          fullWidth
          color="secondary"
          onClick={() => navigate(`/applications/${applicationId}`)}
        >
          Отменить
        </Button>

        <Button
          fullWidth
          onClick={handleContinue}
          disabled={selectedPassengers.length === 0}
        >
          Продолжить{' '}
          {selectedPassengers.length > 0 &&
            `(Выбрано: ${selectedPassengers.length}/${passengers.length})`}
        </Button>
      </Stack>
    </>
  )
}

export default SelectPassengers

import { apiService } from '../apiService'

const driverActions = {
  getFreeDrivers: (bodyData) => apiService.get('drivers/selection/vehicle', { params: bodyData }),
  getAllDrivers: (bodyData) => apiService.get('drivers', { params: bodyData }),
  createDriver: (bodyData) => apiService.post('drivers', bodyData),
  updateDriver: (bodyData) => apiService.put(`drivers/${bodyData.id}`, bodyData),
  getDriver: (driverId) => apiService.get(`drivers/${driverId}`),
}

export default driverActions

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ScrollContainer } from 'react-nice-scroll'
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tab,
  useTheme,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'

import { queryClient } from '../../queryClient'
import applications from '../../endpoints/applications'
import AddPassengersModal from './AddPassengersModal'
import useDeleteConfirmDialog from '../../hooks/DeleteDialog'
import SearchInput from '../../components/ui/SearchInput'
import { buttonData } from '../../enums'

const Passengers = ({ passengers }) => {
  const { common } = useTheme()

  const { applicationId } = useParams()

  const [currentTab, setCurrentTab] = useState('all')

  const [openAddPassengersModal, setOpenAddPassengersModal] = useState(false)

  const [filteredPassengers, setFilteredPassengers] = useState(passengers)

  const [searchQuery, setSearchQuery] = useState('')

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const handleToggleAddPassengersModal = (modalState) => {
    setOpenAddPassengersModal(modalState)
  }

  const tabCounts = {
    all: passengers.length,
    distributed: passengers.filter((passenger) => passenger.processed).length,
    notDistributed: passengers.filter((passenger) => !passenger.processed)
      .length,
  }

  useEffect(() => {
    const allPassengers = passengers || []
    const distributedPassengers = allPassengers.filter(
      (passenger) => passenger.processed
    )
    const notDistributedPassengers = allPassengers.filter(
      (passenger) => !passenger.processed
    )

    const filteredPassengers =
      currentTab === 'all'
        ? allPassengers
        : currentTab === 'distributed'
        ? distributedPassengers
        : notDistributedPassengers

    const searchFilteredPassengers = filteredPassengers.filter((passenger) =>
      passenger.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    setFilteredPassengers(searchFilteredPassengers)
  }, [currentTab, passengers, searchQuery])

  const deletePassenger = async (passengerId) => {
    try {
      await applications.deletePassengerFromApplication(
        applicationId,
        passengerId
      )
      setFilteredPassengers((prev) =>
        prev.filter((passenger) => passenger.id !== passengerId)
      )
      queryClient.invalidateQueries(`applications/${applicationId}`)
      queryClient.invalidateQueries(
        `action-histories/by-application/${applicationId}`
      )
    } catch (err) {
      alert('Ошибка при удалении', err)
    }
  }

  const { DeleteDialog, openDeleteConfirmDialog } = useDeleteConfirmDialog({
    deleteFunc: deletePassenger,
    dialogTitle: 'Вы действительно хотите удалить пассажира?',
  })

  return (
    <Stack
      spacing={3}
      bgcolor={common.colorBgDefault}
      borderRadius="8px"
      py="24px"
    >
      <Typography variant="h5" px={3}>
        Пассажиры
      </Typography>

      <Stack mx={3}>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
            <TabList onChange={handleChange}>
              {buttonData.map((button) => {
                return (
                  <Tab
                    key={button.label}
                    label={button.label + ' ' + tabCounts[button.value]}
                    value={button.value}
                  />
                )
              })}
            </TabList>
          </Box>
        </TabContext>
      </Stack>

      <Stack flexDirection="row" px={3} justifyContent="space-between">
        <SearchInput
          minWidth={250}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Поиск по ФИО"
          sx={{ width: '350px' }}
        />

        <Button onClick={() => handleToggleAddPassengersModal(true)}>
          Добавить пассажира
        </Button>
      </Stack>

      {filteredPassengers?.length > 0 ? (
        <TableContainer>
          <ScrollContainer>
            <Box maxHeight="350px">
              <Table>
                <TableHead bgcolor="lightgray">
                  <TableRow>
                    <TableCell sx={{ color: 'grey' }}>№</TableCell>
                    <TableCell sx={{ color: 'grey' }}>ФИО</TableCell>
                    <TableCell sx={{ color: 'grey' }}>Транспорт</TableCell>
                    <TableCell sx={{ color: 'grey' }}>Место</TableCell>
                    <TableCell sx={{ color: 'grey' }}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredPassengers?.map((passenger, index) => (
                    <TableRow key={passenger.id}>
                      <TableCell component="th" scope="passenger">
                        {index + 1}
                      </TableCell>

                      <TableCell>{passenger.full_name}</TableCell>

                      <TableCell
                        sx={{ color: !passenger?.vehicle_info && 'gray' }}
                      >
                        {passenger?.vehicle_info
                          ? passenger?.vehicle_info
                          : 'Не распеделен'}
                      </TableCell>

                      <TableCell>
                        {passenger?.seat_number
                          ? '№' + passenger?.seat_number
                          : '-'}
                      </TableCell>

                      <TableCell align="right">
                        <DeleteOutlineIcon
                          sx={{
                            color: 'red',
                            cursor: 'pointer',
                            width: 24,
                            height: 20,
                          }}
                          onClick={() => openDeleteConfirmDialog(passenger.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </ScrollContainer>
        </TableContainer>
      ) : (
        <Typography variant="h5" textAlign="center" py={4}>
          Нет пассажиров
        </Typography>
      )}

      <Stack px={3}>
        <Button href="distribution" disabled={tabCounts.notDistributed === 0}>
          Распределить пассажиров
        </Button>
      </Stack>

      <AddPassengersModal
        open={openAddPassengersModal}
        handleToggleAddPassengersModal={handleToggleAddPassengersModal}
      />

      {DeleteDialog}
    </Stack>
  )
}

export default Passengers

import { Button, TableCell, TableRow, Typography } from '@mui/material'

import { extendedStatuses } from '../../enums'
import {
  shortDateFormat,
  dateTimeFormat,
  weekDayMonthFormat,
} from '../../utils/dateUtils'

const ApplicationItem = ({
  application,
  setApplicationId,
  handleToggleModal,
}) => {
  const status = extendedStatuses.find(
    (item) => item.value === application?.status
  )

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="application">
        <Typography variant="subtitle2">№{application.id}</Typography>

        <Typography variant="body2" sx={{ opacity: 0.5 }}>
          {application?.created_at
            ? `${shortDateFormat(application?.created_at)}, 
                ${dateTimeFormat(application?.created_at)}`
            : ''}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">
          {application.source_type === 'own'
            ? 'Odyssey drive'
            : 'application.source_type'}
        </Typography>

        <Typography variant="body2" sx={{ opacity: 0.5 }}>
          {application.source_type === 'own'
            ? `${application.user_created.surname} ${
                application?.user_created?.name &&
                application?.user_created?.name?.slice(0, 1) + '.'
              } ${
                application?.user_created?.middle_name &&
                application?.user_created?.middle_name?.slice(0, 1) + '.'
              }`
            : application?.external_user_created}
        </Typography>
      </TableCell>

      <TableCell>
        {application.from_point.name} - {application.to_point.name}
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">
          {dateTimeFormat(application.action_date)}
        </Typography>

        {weekDayMonthFormat(application.action_date)}
      </TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2">
          {application.passenger_count}

          {application.processed_passenger_count > 0 &&
            '/' + application.processed_passenger_count}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        <Typography
          variant="subtitle2"
          sx={{
            backgroundColor: `${status?.bgcolor}`,
            color: `${status?.color}`,
            padding: '6px 12px',
            borderRadius: '16px',
            display: 'inline',
          }}
        >
          {status?.text}
        </Typography>
      </TableCell>

      <TableCell>
        <Button
          onClick={() => {
            setApplicationId(application.id)
            handleToggleModal(true)
          }}
        >
          Подробнее
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default ApplicationItem

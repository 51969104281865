import { Outlet, useNavigation, ScrollRestoration } from 'react-router-dom'
import {
  Box,
  Drawer,
  LinearProgress,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'

import Header from './Header'
import SideMenu from './SideMenu'

const drawerWidth = 240

const CustomizedDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: '#2B2B2B',
    height: '100vh',
  },
})

const Layouts = () => {
  const navigation = useNavigation()

  const { common } = useTheme()

  return (
    <Box
      sx={{
        minHeight: '100vh',
        minWidth: 1440,
        backgroundColor: common.colorBgSecondary,
      }}
    >
      <ScrollRestoration />

      <CustomizedDrawer variant="permanent">
        <Toolbar
          disableGutters={true}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: '20px',
          }}
        >
          <Typography variant="h5" color={'#FFFFFF'}>
            Odyssey Drive
          </Typography>
        </Toolbar>

        <SideMenu />
      </CustomizedDrawer>

      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          pb: 8,
        }}
      >
        {navigation.state === 'loading' && (
          <LinearProgress
            sx={{ position: 'absolute', width: '100%', top: 0 }}
          />
        )}

        <Header />

        <Box sx={{ width: 1150, mx: 'auto', mt: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default Layouts

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { ScrollContainer } from 'react-nice-scroll'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import TransportItem from './TransportItem'

const DialogWindow = ({
  open,
  handleToggleDialog,
  selectedVariant,
  selectedVariantIndex,
  handleChooseVariant,
  handleContinue,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={() => handleToggleDialog(false, null)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Вариант №{selectedVariantIndex + 1}{' '}
        {selectedVariantIndex <= 2 && 'Оптимальный'}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => handleToggleDialog(false, null)}
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <TableContainer>
          <ScrollContainer>
            <Box maxHeight="600px">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'grey' }}>Автомобиль</TableCell>

                    <TableCell sx={{ color: 'grey' }}>
                      Ближайшие задания
                    </TableCell>

                    <TableCell sx={{ color: 'grey' }} align="center">
                      Статус
                    </TableCell>

                    <TableCell sx={{ color: 'grey' }}>Примечание</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedVariant?.map((transport) => (
                    <TransportItem key={transport.id} transport={transport} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </ScrollContainer>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ m: 2 }}>
        <Button
          onClick={() => {
            handleChooseVariant(selectedVariant)
            handleToggleDialog(false, null)
            handleContinue()
          }}
        >
          Выбрать вариант
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogWindow

import { useState } from 'react'
import { useQuery } from 'react-query'
import {
  Button,
  IconButton,
  Typography,
  Tab,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { extendedStatuses } from '../../../enums'
import applications from '../../../endpoints/applications'
import Details from './Details'
import Passengers from './Passengers'
import Tasks from './Tasks'

const ApplicationModal = ({
  open,
  handleToggleModal,
  applicationId,
  currentTab: parentCurrentTab,
}) => {
  const [currentTab, setCurrentTab] = useState('details')

  const {
    data: application,
    isError: applicationError,
    isLoading: applicationLoading,
  } = useQuery(
    `application/${applicationId}`,
    () => applications.getApplicationById(applicationId),
    { enabled: !!applicationId, retry: 1, refetchOnWindowFocus: false }
  )

  const { passengers, tasks } = application?.data || {}

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const status = extendedStatuses.find(
    (item) => item.value === application?.data?.status
  )

  if (applicationLoading) {
    return (
      <Backdrop
        open={applicationLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleToggleModal(false)}
      maxWidth="md"
      scroll="paper"
      fullWidth={true}
    >
      <IconButton
        onClick={() => handleToggleModal(false)}
        sx={{
          position: 'absolute',
          top: '24px',
          right: '24px',
          width: '32px',
          height: '32px',
        }}
      >
        <HighlightOffIcon sx={{ width: '32px', height: '32px' }} />
      </IconButton>

      {applicationError ? (
        <Typography py={10} textAlign="center" variant="h5">
          Ошибка при загрузке заявки ...
        </Typography>
      ) : (
        <>
          <DialogTitle>Заявка №{applicationId}</DialogTitle>

          <DialogContent>
            <TabContext value={currentTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Детали заявки" value="details" />

                  <Tab label="Пассажиры" value="passengers" />

                  <Tab label="Задание" value="tasks" />
                </TabList>
              </Box>

              <TabPanel value="details" sx={{ px: '0px' }}>
                <Details application={application?.data} status={status} />
              </TabPanel>

              <TabPanel value="passengers" sx={{ px: '0px' }}>
                <Passengers passengers={passengers} />
              </TabPanel>

              <TabPanel value="tasks" sx={{ px: '0px' }}>
                <Tasks tasks={tasks} />
              </TabPanel>
            </TabContext>
          </DialogContent>

          <DialogActions
            sx={{
              m: '0px 14px 14px 14px',
              display:
                parentCurrentTab === 'archive' ||
                application?.data?.status === 'rejected'
                  ? 'none'
                  : 'flex',
            }}
          >
            <Button fullWidth href={`/applications/${applicationId}`}>
              Перейти к заявке
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ApplicationModal

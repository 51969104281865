import { useEffect } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GridViewIcon from '@mui/icons-material/GridView'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MenuIcon from '@mui/icons-material/Menu'

import { formatDate, dateTimeFormat } from '../../../utils/dateUtils'

const TransportItem = ({
  name,
  id,
  state_number,
  current_driver,
  passengers,
  free_seat_count,
  free_seats,
  setDeletedPassenger,
  expanded,
  setExpanded,
  transports,
  setCurrentTransport,
  value,
  actionDate,
  transport,
}) => {
  const d = formatDate(actionDate)

  useEffect(() => {
    if (transport.suitable_task) {
      transport.startTime = transport.suitable_task.action_start_time
      transport.endTime = transport.suitable_task.action_end_time
    } else if (transport.right_split_task) {
      transport.startTime = transport.right_split_task.action_end_time
      transport.endTime = formatDate(
        transport.right_split_task.action_end_time
      ).plus({
        hours: value[1] - value[0] + 6,
      })
    } else {
      transport.startTime = d.plus({ hours: value[0] + 6 })
      transport.endTime = d.plus({ hours: value[1] + 6 })
    }
  }, [transport, d, value])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    setCurrentTransport(
      isExpanded
        ? transports.findIndex((transport) => transport.id === panel)
        : null
    )
  }

  return (
    <Droppable droppableId={id.toString()}>
      {(provided) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            border: `1px solid ${expanded === id ? '#3291FF' : '#F0F0F0'}`,
            mt: '2px',
            boxShadow: 'none',
          }}
          expanded={expanded === id}
          onChange={handleChange(id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ borderRadius: '12px' }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Stack sx={{ mr: 'auto' }}>
                <Typography
                  variant="subtitle2"
                  color={expanded === id ? '#3291FF' : '#121F33'}
                >
                  {name}
                </Typography>

                <Typography variant="body2" sx={{ color: 'grey' }}>
                  {state_number},{' '}
                  {current_driver?.surname +
                    ' ' +
                    current_driver?.name[0] +
                    // '.' +
                    // current_driver?.middle_name && current_driver?.middle_name[0] +
                    '.'}
                </Typography>
              </Stack>

              <Typography mr={2}>
                {passengers.length}/{free_seat_count}
              </Typography>

              <Typography
                variant="subtitle2"
                mr={3}
                sx={{ p: '6px 8px', bgcolor: '#F0F0F0', borderRadius: '6px' }}
              >
                {dateTimeFormat(transport.startTime)} -{' '}
                {dateTimeFormat(transport.endTime)}
              </Typography>

              <GridViewIcon />
            </Stack>
          </AccordionSummary>

          <AccordionDetails sx={{ borderRadius: '12px' }}>
            <Stack width="100%" direction="row" justifyContent="space-between">
              <Stack>
                {free_seats.map((seat, index) => (
                  <Typography
                    key={index}
                    display="flex"
                    alignItems="center"
                    lineHeight={2.6}
                    mb="1px"
                  >
                    №{seat}
                  </Typography>
                ))}
              </Stack>

              <Stack width="100%" ml={2}>
                {passengers
                  ? passengers?.map((passenger, index) => (
                      <Draggable
                        key={passenger?.id.toString()}
                        draggableId={passenger?.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          return (
                            <Stack
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              direction="row"
                              width={1}
                              justifyContent="space-between"
                              alignItems="center"
                              borderBottom="1px solid #F0F0F0"
                            >
                              <Typography
                                ref={provided.innerRef}
                                color={snapshot.isDragging && 'blue'}
                                width={'100%'}
                                lineHeight={2.6}
                              >
                                {passenger?.full_name}
                              </Typography>

                              <>
                                <ArrowBackIcon
                                  onClick={() => setDeletedPassenger(passenger)}
                                  sx={{
                                    cursor: 'pointer',
                                    color: '#9DA5B2',
                                    mr: 1,
                                  }}
                                />

                                <MenuIcon sx={{ mr: 1, cursor: 'grab' }} />
                              </>
                            </Stack>
                          )
                        }}
                      </Draggable>
                    ))
                  : null}
              </Stack>
            </Stack>

            {provided.placeholder}
          </AccordionDetails>
        </Accordion>
      )}
    </Droppable>
  )
}

export default TransportItem

import { useState, useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Stack, Typography, Button, Checkbox, Box } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import SearchInput from '../../../components/ui/SearchInput'
import TransportItem from './TransportItem'

const ChoosePlaces = ({
  selectedPassengers,
  selectedTransports,
  setDistributedPassengers,
  handleNextStep,
  handleStepBack,
  value,
  actionDate,
}) => {
  const [transports, setTransports] = useState(() =>
    selectedTransports.map((transport) => ({
      ...transport,
      passengers: [],
    }))
  )

  const [passengers, setPassengers] = useState(selectedPassengers)

  const [deletedPassenger, setDeletedPassenger] = useState(null)

  const [isCompleted, setIsCompleted] = useState(false)

  const [checkedPassengers, setCheckedPassengers] = useState([])

  const [expanded, setExpanded] = useState(false)

  const [currentTransport, setCurrentTransport] = useState(null)

  const [filteredPassengers, setFilteredPassengers] = useState(passengers)

  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const searchFilteredPassengers = passengers.filter((passenger) =>
      passenger.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    setFilteredPassengers(searchFilteredPassengers)
  }, [searchQuery, passengers])

  useEffect(() => {
    const data = [...transports]

    data?.forEach((vehicle) => {
      const passenger_seat_pairs = []

      vehicle?.passengers?.forEach((passenger, index) => {
        const seat_number = vehicle.free_seats[index]
        if (seat_number !== undefined) {
          passenger_seat_pairs.push({
            passenger_id: passenger?.id,
            seat_number: seat_number,
          })
        }
      })

      vehicle.passenger_seat_pairs = passenger_seat_pairs
    })

    setDistributedPassengers(data)
  }, [transports, setDistributedPassengers])

  useEffect(() => {
    if (deletedPassenger) {
      setPassengers([...passengers, deletedPassenger])
      setDeletedPassenger(null)
      transports.forEach((transport) => {
        const passengerIndex = transport.passengers.findIndex(
          (passenger) => passenger.id === deletedPassenger.id
        )
        const transportIndex = transports.findIndex(
          (item) => item.id === transport.id
        )

        if (passengerIndex !== -1) {
          const newTransports = [...transports]
          newTransports[transportIndex].passengers.splice(passengerIndex, 1)
          setTransports(newTransports)
        }
      })
    }
  }, [deletedPassenger, passengers, transports])

  useEffect(() => {
    for (let i = 0; i < transports.length; i++) {
      if (transports[i].passenger_seat_pairs.length === 0) {
        setIsCompleted(false)
        break
      } else {
        setIsCompleted(true)
      }
    }
  }, [passengers, transports])

  const handleDistributeByOne = (passenger) => {
    if (currentTransport === null) {
      alert('Выберите транспорт для пассажиров')
      return
    }

    if (
      transports[currentTransport]?.free_seat_count -
        transports[currentTransport]?.passengers.length ===
      0
    ) {
      alert('Недостаточно мест')
      return
    }

    const passenger_seat_pairs =
      transports[currentTransport].passenger_seat_pairs

    const distributedPass = transports[currentTransport].passengers
    const freeSeat =
      transports[currentTransport].free_seats[
        transports[currentTransport].passenger_seat_pairs.length
      ]

    passenger_seat_pairs.push({
      passenger_id: passenger.id,
      seat_number: freeSeat,
    })

    distributedPass.push(passenger)

    setPassengers(passengers.filter((item) => item !== passenger))

    transports[currentTransport].passenger_seat_pairs = passenger_seat_pairs
    transports[currentTransport].passengers = distributedPass

    setCheckedPassengers([])
  }

  const handleDistributePassengers = () => {
    const passenger_seat_pairs =
      transports[currentTransport].passenger_seat_pairs

    const distributedPass = transports[currentTransport].passengers
    distributedPass.push(...checkedPassengers)

    for (const passenger of checkedPassengers) {
      const freeSeat =
        transports[currentTransport].free_seats[
          transports[currentTransport].passenger_seat_pairs.length
        ]

      passenger_seat_pairs.push({
        passenger_id: passenger.id,
        seat_number: freeSeat,
      })
    }

    setPassengers(
      passengers.filter((passenger) => !checkedPassengers.includes(passenger))
    )

    transports[currentTransport].passenger_seat_pairs = passenger_seat_pairs
    transports[currentTransport].passengers = distributedPass
    setCheckedPassengers([])
  }

  const handleDragEnd = (result) => {
    const { destination, source } = result

    const newTransports = [...transports]

    const newPassengers = [...passengers]

    const desId = destination?.droppableId
    const srcId = source?.droppableId

    const transportsDestinationIndex = transports.findIndex(
      (transport) => transport?.id === Number(desId)
    )

    const transportsSourceIndex = transports.findIndex(
      (transport) => transport.id === Number(srcId)
    )

    const removedPassenger =
      newTransports[transportsSourceIndex].passengers[source.index]

    if (!destination) {
      return
    }

    if (
      transports[transportsDestinationIndex]?.seat_count <=
        transports[transportsDestinationIndex]?.passengers.length &&
      srcId !== desId
    ) {
      return
    }

    if (desId !== srcId) {
      setPassengers(newPassengers)
      newTransports[transportsDestinationIndex].passengers.splice(
        destination.index,
        0,
        removedPassenger
      )
      newTransports[transportsSourceIndex].passengers.splice(source.index, 1)
      setTransports(newTransports)
    } else if (desId === srcId) {
      newTransports[transportsDestinationIndex].passengers.splice(
        source.index,
        1
      )
      newTransports[transportsDestinationIndex].passengers.splice(
        destination.index,
        0,
        removedPassenger
      )
      setTransports(newTransports)
    }
  }

  const handleAutoDistribution = () => {
    const updatedPassengers = [...passengers]

    for (let transport of transports) {
      const passenger_seat_pairs = transport.passenger_seat_pairs

      const distributedPass = transport.passengers

      const freeSeatsNumber = transport.free_seats.filter(
        (item) =>
          !passenger_seat_pairs.some((pair) => pair.seat_number === item)
      )

      for (
        let j = 0;
        j < freeSeatsNumber.length && updatedPassengers.length > 0;
        j++
      ) {
        const freeSeat = freeSeatsNumber[j]
        const passenger = updatedPassengers.shift()

        if (freeSeat !== undefined && passenger !== undefined) {
          passenger_seat_pairs.push({
            passenger_id: passenger.id,
            seat_number: freeSeat,
          })
          distributedPass.push(passenger)
        }
      }

      transport.passenger_seat_pairs = passenger_seat_pairs
      transport.passengers = distributedPass
    }

    setPassengers(updatedPassengers)
  }

  const handleContinue = () => {
    handleNextStep()
  }

  const handleBack = () => {
    handleStepBack()
  }

  return (
    <Stack spacing={5}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack
            spacing={4}
            width="50%"
            bgcolor="#FFFFFF"
            borderRadius={1}
            padding="24px 16px"
          >
            <Typography variant="h5">
              Пассажиры{' '}
              <span style={{ color: 'gray' }}>{passengers?.length}</span>
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SearchInput
                minWidth={150}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Поиск"
                sx={{ width: '250px' }}
              />

              <Button
                onClick={handleAutoDistribution}
                disabled={passengers.length === 0}
              >
                Автозаполнение мест
              </Button>
            </Stack>

            <Box
              maxHeight="400px"
              height="400px"
              sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '7px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,.1)',
                  borderRadius: '7px',
                },
              }}
            >
              {filteredPassengers?.length === 0 ? (
                <Typography sx={{ color: 'gray' }}>
                  Все пассажиры распределены по местам.
                </Typography>
              ) : (
                filteredPassengers?.map((passenger) => (
                  <Stack key={passenger.id} direction="row" height="48px">
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedPassengers([
                            ...checkedPassengers,
                            passenger,
                          ])
                        } else {
                          const newCheckedPassengers = checkedPassengers.filter(
                            (item) => item !== passenger
                          )
                          setCheckedPassengers(newCheckedPassengers)
                        }
                      }}
                      mb={1}
                    />

                    <Typography
                      borderBottom="1px solid lightgray"
                      width="100%"
                      mt={1.5}
                      pl={1}
                      onClick={() => handleDistributeByOne(passenger)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {passenger.full_name}
                    </Typography>
                  </Stack>
                ))
              )}
            </Box>

            <Stack direction="row" justifyContent="center" position="relative">
              {checkedPassengers.length > 0 && currentTransport !== null && (
                <Button
                  sx={{
                    position: 'absolute',
                    bottom: '0px',
                    alignItems: 'center',
                  }}
                  disabled={
                    transports[currentTransport].free_seat_count -
                      transports[currentTransport].passengers.length <
                    checkedPassengers.length
                  }
                  onClick={handleDistributePassengers}
                >
                  {transports[currentTransport].free_seat_count -
                    transports[currentTransport].passengers.length <
                  checkedPassengers.length
                    ? 'Недостаточно мест'
                    : `Посадить выбранных: ${checkedPassengers.length}`}{' '}
                  <ArrowForwardIcon sx={{ ml: 1 }} />
                </Button>
              )}
            </Stack>
          </Stack>

          <Stack
            spacing={4}
            width="50%"
            bgcolor="#FFFFFF"
            borderRadius={1}
            padding="24px 16px"
            position="relative"
          >
            <Typography variant="h5">Места в транспорте</Typography>

            <Stack
              maxHeight="400px"
              height="400px"
              sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '7px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,.1)',
                  borderRadius: '7px',
                },
              }}
            >
              {transports.map((transport) => (
                <TransportItem
                  {...transport}
                  transport={transport}
                  transports={transports}
                  key={transport.id}
                  free_seats={transport.free_seats}
                  deletedPassenger={deletedPassenger}
                  setDeletedPassenger={setDeletedPassenger}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  currentTransport={currentTransport}
                  setCurrentTransport={setCurrentTransport}
                  actionDate={actionDate}
                  value={value}
                />
              ))}
            </Stack>

            {checkedPassengers.length > 0 && currentTransport === null && (
              <Stack direction="row" justifyContent="center">
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: '20px',
                    bgcolor: '#1A2C47',
                    borderRadius: '6px',
                    padding: '6px',
                    opacity: '0.8',
                    color: '#FFFFFF',
                  }}
                >
                  Выберите транспорт для пассажиров
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </DragDropContext>

      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Button fullWidth color="secondary" onClick={handleBack}>
          Назад
        </Button>

        <Button fullWidth onClick={handleContinue} disabled={!isCompleted}>
          Продолжить
        </Button>
      </Stack>
    </Stack>
  )
}

export default ChoosePlaces

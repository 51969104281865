import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { dateTimeFormat } from '../../utils/dateUtils'

export function TaskListByStatus({
  activeTasks,
  onProcessTasks,
  completedTasks,
  rejectedTasks,
  handleToggleModal,
}) {
  return (
    <Stack spacing={1.5}>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">
            Запланированные {activeTasks?.length}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mx: '-16px' }}>
          {activeTasks?.length === 0 ? (
            <Typography textAlign="center" my={3}>
              Пусто
            </Typography>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
                <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                  <TableRow>
                    <TableCell>ЗАДАНИЕ</TableCell>
                    <TableCell>ТРАНСПОРТ</TableCell>
                    <TableCell>ПЛАН. ВРЕМЯ</TableCell>
                    <TableCell>ФАКТ. ВРЕМЯ</TableCell>
                    <TableCell align="center">СТАТУС</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {activeTasks?.map((task) => {
                    return (
                      <TableRow key={task.id}>
                        <TableCell component="th" scope="application">
                          <Typography variant="subtitle2">
                            {task?.from_point.name} - {task?.to_point.name}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            №{task?.id}, {task?.passenger_count} чел.
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle2">
                            {task?.vehicle?.mark?.name}{' '}
                            {task?.vehicle?.model?.name} {task?.vehicle?.year}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            {task?.vehicle?.state_number}
                            {', '}
                            {task?.driver?.surname} {task?.driver?.name[0]}
                            {'. '}
                            {task?.driver?.middle_name &&
                              task?.driver?.middle_name[0] + '.'}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          {dateTimeFormat(task?.action_start_time)} -{' '}
                          {dateTimeFormat(task?.action_end_time)}
                        </TableCell>

                        <TableCell>
                          {task?.actual_start_time &&
                            task?.actual_end_time &&
                            dateTimeFormat(task?.actual_start_time) +
                              ' - ' +
                              dateTimeFormat(task?.actual_end_time)}
                        </TableCell>

                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              backgroundColor: '#E8F2FF',
                              color: '#3291FF',
                              padding: '6px 12px',
                              borderRadius: '16px',
                              display: 'inline',
                            }}
                          >
                            Ожидает
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <img
                            alt="Arrow right icon"
                            src={require(`../../assets/images/ArrorRight.png`)}
                            onClick={() => handleToggleModal(true, task?.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">
            В процессе выполнения {onProcessTasks?.length}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mx: '-16px' }}>
          {onProcessTasks?.length === 0 ? (
            <Typography textAlign="center" my={3}>
              Пусто
            </Typography>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
                <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                  <TableRow>
                    <TableCell>Задание</TableCell>
                    <TableCell>Транспорт</TableCell>
                    <TableCell>План. Время</TableCell>
                    <TableCell>ФАКТ. ВРЕМЯ</TableCell>
                    <TableCell align="center">Статус</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {onProcessTasks?.map((task) => {
                    return (
                      <TableRow key={task.id}>
                        <TableCell component="th" scope="application">
                          <Typography variant="subtitle2">
                            {task?.from_point.name} - {task?.to_point.name}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            №{task?.id}, {task?.passenger_count} чел.
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle2">
                            {task?.vehicle?.mark?.name}{' '}
                            {task?.vehicle?.model?.name} {task?.vehicle?.year}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            {task?.vehicle?.state_number}
                            {', '}
                            {task?.driver?.surname} {task?.driver?.name[0]}
                            {'. '}
                            {task?.driver?.middle_name &&
                              task?.driver?.middle_name[0] + '.'}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          {dateTimeFormat(task?.action_start_time)} -{' '}
                          {dateTimeFormat(task?.action_end_time)}
                        </TableCell>

                        <TableCell>
                          {task?.actual_start_time &&
                            task?.actual_end_time &&
                            dateTimeFormat(task?.actual_start_time) +
                              ' - ' +
                              dateTimeFormat(task?.actual_end_time)}
                        </TableCell>

                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              backgroundColor: '#FFF0B3',
                              color: '#121F33',
                              padding: '6px 12px',
                              borderRadius: '16px',
                              display: 'inline',
                            }}
                          >
                            В работе
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <img
                            alt="Arrow right icon"
                            src={require(`../../assets/images/ArrorRight.png`)}
                            onClick={() => handleToggleModal(true, task?.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">
            Завершены {completedTasks?.length}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mx: '-16px' }}>
          {completedTasks?.length === 0 ? (
            <Typography textAlign="center" my={3}>
              Пусто
            </Typography>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
                <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                  <TableRow>
                    <TableCell>Задание</TableCell>
                    <TableCell>Транспорт</TableCell>
                    <TableCell>План. Время</TableCell>
                    <TableCell>ФАКТ. ВРЕМЯ</TableCell>
                    <TableCell align="center">Статус</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {completedTasks?.map((task) => {
                    return (
                      <TableRow key={task.id}>
                        <TableCell component="th" scope="application">
                          <Typography variant="subtitle2">
                            {task?.from_point.name} - {task?.to_point.name}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            №{task?.id}, {task?.passenger_count} чел.
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle2">
                            {task?.vehicle?.mark?.name}{' '}
                            {task?.vehicle?.model?.name} {task?.vehicle?.year}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            {task?.vehicle?.state_number}
                            {', '}
                            {task?.driver?.surname} {task?.driver?.name[0]}
                            {'. '}
                            {task?.driver?.middle_name &&
                              task?.driver?.middle_name[0] + '.'}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          {dateTimeFormat(task?.action_start_time)} -{' '}
                          {dateTimeFormat(task?.action_end_time)}
                        </TableCell>

                        <TableCell>
                          {task?.actual_start_time &&
                            task?.actual_end_time &&
                            dateTimeFormat(task?.actual_start_time) +
                              ' - ' +
                              dateTimeFormat(task?.actual_end_time)}
                        </TableCell>

                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              backgroundColor: '#E6F5F2',
                              color: '#00A791',
                              padding: '6px 12px',
                              borderRadius: '16px',
                              display: 'inline',
                            }}
                          >
                            Завершено
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <img
                            alt="Arrow right icon"
                            src={require(`../../assets/images/ArrorRight.png`)}
                            onClick={() => handleToggleModal(true, task?.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Отменено {rejectedTasks?.length}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mx: '-16px' }}>
          {rejectedTasks?.length === 0 ? (
            <Typography textAlign="center" my={3}>
              Пусто
            </Typography>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
                <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                  <TableRow>
                    <TableCell>Задание</TableCell>
                    <TableCell>Транспорт</TableCell>
                    <TableCell>План. Время</TableCell>
                    <TableCell>ФАКТ. ВРЕМЯ</TableCell>
                    <TableCell align="center">Статус</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rejectedTasks?.map((task) => {
                    return (
                      <TableRow key={task.id}>
                        <TableCell component="th" scope="application">
                          <Typography variant="subtitle2">
                            {task?.from_point.name} - {task?.to_point.name}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            №{task?.id}, {task?.passenger_count} чел.
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle2">
                            {task?.vehicle?.mark?.name}{' '}
                            {task?.vehicle?.model?.name} {task?.vehicle?.year}
                          </Typography>

                          <Typography variant="body2" sx={{ opacity: 0.5 }}>
                            {task?.vehicle?.state_number}
                            {', '}
                            {task?.driver?.surname} {task?.driver?.name[0]}
                            {'. '}
                            {task?.driver?.middle_name &&
                              task?.driver?.middle_name[0] + '.'}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          {dateTimeFormat(task?.action_start_time)} -{' '}
                          {dateTimeFormat(task?.action_end_time)}
                        </TableCell>

                        <TableCell>
                          {task?.actual_start_time &&
                            task?.actual_end_time &&
                            dateTimeFormat(task?.actual_start_time) +
                              ' - ' +
                              dateTimeFormat(task?.actual_end_time)}
                        </TableCell>

                        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              backgroundColor: '#9DA5B2',
                              color: '#EBEDF0',
                              padding: '6px 12px',
                              borderRadius: '16px',
                              display: 'inline',
                            }}
                          >
                            Отменено
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <img
                            alt="Arrow right icon"
                            src={require(`../../assets/images/ArrorRight.png`)}
                            onClick={() => handleToggleModal(true, task?.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}

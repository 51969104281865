import { useState } from 'react'
import { Stack, Avatar, Typography, Menu, MenuItem } from '@mui/material'
import { useSnapshot } from 'valtio'
import { useNavigate } from 'react-router-dom'

import { store } from '../../store'

const Header = () => {
  const navigate = useNavigate()

  const { user } = useSnapshot(store)

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      direction="row"
      height={72}
      justifyContent="flex-end"
      alignItems="center"
      bgcolor="#FFFFFF"
      px={2}
    >
      <Stack
        direction="row"
        spacing={1}
        onClick={handleClick}
        sx={{ cursor: 'pointer', height: '40px' }}
      >
        <Avatar alt={user?.name} sx={{ width: 40, height: 40 }} />

        <Stack>
          <Typography>
            {user?.surname} {user?.name}
          </Typography>

          <Typography variant="body2">{user?.position}</Typography>
        </Stack>
      </Stack>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Профиль</MenuItem>

        <MenuItem
          onClick={() => {
            localStorage.removeItem('token')
            store.user = null
            store.auth.isAuthenticated = false
            navigate('/login')
          }}
        >
          Выйти
        </MenuItem>
      </Menu>
    </Stack>
  )
}

export default Header

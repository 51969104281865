import { TableCell, TableRow, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

function TransportItem({ transport, toggleDrawer }) {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">
          {transport.mark.name} {transport.model.name}
        </Typography>

        <Typography variant="body2" sx={{ color: 'gray' }}>
          {transport?.year} • {transport?.seat_count} мест
        </Typography>
      </TableCell>

      <TableCell>{transport?.state_number}</TableCell>

      <TableCell>
        {transport?.current_driver?.surname}{' '}
        {transport?.current_driver?.name[0]}.{' '}
      </TableCell>

      <TableCell>{transport?.appointment_type?.name}</TableCell>

      <TableCell>
        <EditIcon
          sx={{ cursor: 'pointer' }}
          onClick={toggleDrawer(true, transport)}
        />
      </TableCell>
    </TableRow>
  )
}

export default TransportItem

import { useState, useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash.debounce'
import {
  Stack,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Drawer,
} from '@mui/material'

import TransportItem from './TransportItem'
import vehicles from '../../endpoints/vehicles'
import driverActions from '../../endpoints/drivers'
import TableSkeleton from '../../components/ui/TableSkeleton'
import CustomPagination from '../../components/ui/Pagination'
import SearchInput from '../../components/ui/SearchInput'
import references from '../../endpoints/references'
import { Form } from './Form'

const List = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const [pageCount, setPageCount] = useState(1)

  const [search, setSearch] = useState('')

  const [markId, setMarkId] = useState(null)

  const [models, setModels] = useState(null)

  const [modelId, setModelId] = useState(null)

  const [appointmentTypeId, setAppointmentTypeId] = useState(null)

  const [firstLoad, setFirstLoad] = useState(true)

  const [openDrawer, setOpenDrawer] = useState(false)

  const [transport, setTransport] = useState(null)

  const [drivers, setDrivers] = useState([])

  useEffect(() => {
    if (transport !== null) {
      driverActions
        .getFreeDrivers(
          transport !== 'new' && { driver_id: transport?.current_driver?.id }
        )
        .then((res) => {
          setDrivers(res?.data)
        })
        .catch((err) => console.log(err))
    }
  }, [transport])

  const {
    data: markData,
    isLoading: isMarksLoading,
    isError: isMarksError,
  } = useQuery('references/car-mark', () => references.getCarMarks(), {
    retry: 2,
  })

  const {
    data: appointmentTypes,
    isLoading: isAppointmentTypesLoading,
    isError: isAppointmentTypesError,
  } = useQuery(
    'references/appointment-type',
    () => references.getAppointmentTypes(),
    {
      retry: 2,
    }
  )

  const {
    data: transportsData,
    isLoading: isTransportsLoading,
    isError: isTransportError,
  } = useQuery(
    ['vehicle', search, currentPage, markId, modelId, appointmentTypeId],
    () =>
      vehicles.getVehicles({
        page: currentPage,
        keyword: search,
        mark_id: markId,
        model_id: markId ? modelId : '',
        appointment_type_id: appointmentTypeId,
      }),
    {
      onSuccess: (data) => {
        setPageCount(Math.ceil(data?.data?.total / data?.data?.limit))
        setFirstLoad(false)
      },
    }
  )

  const transports = useMemo(() => transportsData?.data?.data, [transportsData])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const debouncedSearch = useMemo(() => {
    return debounce((e) => {
      setSearch(e.target.value)
      setCurrentPage(1)
    }, 1000)
  }, [])

  const toggleDrawer = (modalState, transport) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpenDrawer(modalState)
    setTransport(transport)
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  if (isTransportError) {
    return 'Ошибка при загрузке данных'
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Транспорты</Typography>

        <Button onClick={toggleDrawer(true, 'new')}>Создать транспорт</Button>
      </Stack>

      <Stack flexDirection="row">
        <FormControl
          sx={{
            mr: '12px',
            '.MuiOutlinedInput-notchedOutline': { border: '1px solid #FFFFFF' },
            minWidth: '255px',
          }}
          error={isMarksError}
        >
          {isMarksLoading ? (
            <InputLabel>Загрузка...</InputLabel>
          ) : isMarksError ? (
            <InputLabel>Ошибка при загрузке марок...</InputLabel>
          ) : (
            <>
              <InputLabel>Марки</InputLabel>

              <Select
                value={markId || ''}
                label="Марка"
                onChange={(e) => {
                  setMarkId(e.target.value)
                  if (e.target.value === null) {
                    setModels(null)
                  } else {
                    setModels(
                      markData?.data?.find((mark) => mark.id === e.target.value)
                        .models
                    )
                  }
                }}
              >
                <MenuItem value={null}>Все</MenuItem>

                {markData?.data?.map((mark) => (
                  <MenuItem key={mark.id} value={mark.id}>
                    {mark.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>

        {markId && (
          <FormControl
            sx={{
              mr: '12px',
              '.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #FFFFFF',
              },
            }}
          >
            <InputLabel>Модель</InputLabel>

            <Select
              value={modelId || ''}
              label="Модель"
              onChange={(e) => setModelId(e.target.value)}
            >
              <MenuItem value={null}>Все</MenuItem>
              {models?.map((model) => (
                <MenuItem key={model.id} value={model.id}>
                  {model.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl
          sx={{
            mr: '12px',
            '.MuiOutlinedInput-notchedOutline': { border: '1px solid #FFFFFF' },
            minWidth: '255px',
          }}
          error={isMarksError}
        >
          {isAppointmentTypesLoading ? (
            <InputLabel>Загрузка...</InputLabel>
          ) : isAppointmentTypesError ? (
            <InputLabel>Ошибка при загрузке марок...</InputLabel>
          ) : (
            <>
              <InputLabel>Тип назначении</InputLabel>

              <Select
                value={appointmentTypeId || ''}
                label="Тип назначении"
                onChange={(e) => setAppointmentTypeId(e.target.value)}
              >
                <MenuItem value={null}>Все</MenuItem>

                {appointmentTypes?.data?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>

        <SearchInput
          minWidth={230}
          onChange={debouncedSearch}
          placeholder="Поиск по Гос. номеру..."
          sx={{ marginLeft: 'auto', width: '270px' }}
        />
      </Stack>

      {isTransportsLoading && firstLoad ? (
        <LinearProgress />
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
              <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                <TableRow>
                  <TableCell>Автомобиль</TableCell>
                  <TableCell>Номер</TableCell>
                  <TableCell>Водители</TableCell>
                  <TableCell>Назначение</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {isTransportsLoading ? (
                <TableSkeleton rowsNum={10} columnsNum={7} />
              ) : (
                <TableBody>
                  {transports?.length === 0 ? (
                    <Typography textAlign="center" my={3}>
                      Пусто
                    </Typography>
                  ) : (
                    transports?.map((transport) => {
                      return (
                        <TransportItem
                          key={transport.id}
                          transport={transport}
                          toggleDrawer={toggleDrawer}
                          transports={transports}
                        />
                      )
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <CustomPagination
            count={pageCount}
            page={currentPage}
            handleChangePage={handleChangePage}
          />

          <Drawer
            anchor={'right'}
            open={openDrawer}
            onClose={toggleDrawer(false, null)}
          >
            <Form
              transport={transport}
              marks={markData?.data}
              appointmentTypes={appointmentTypes?.data}
              toggleDrawer={toggleDrawer}
              closeDrawer={closeDrawer}
              drivers={drivers}
            />
          </Drawer>
        </>
      )}
    </Stack>
  )
}

export default List

import { apiService } from '../apiService'

const vehicles = {
  //vehicle actions
  getActions: (bodyData) =>
    apiService.post(`vehicle/actions/${bodyData.type}`, bodyData),

  // vehicle CRUD
  getVehicles: (bodyData) => apiService.get('vehicle', { params: bodyData }),
  createVehicle: (bodyData) => apiService.post('vehicle', bodyData),
  updateVehicle: (bodyData) =>
    apiService.put(`vehicle/${bodyData.id}`, bodyData),

  getDailyInfo: () => apiService.get('vehicle/actions/daily-info'),
}

export default vehicles

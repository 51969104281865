import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useSnapshot } from 'valtio'

import { store } from './store'
import account from './endpoints/account'
import Layouts from './components/layouts/Layouts'

const App = () => {
  const navigate = useNavigate()

  const { isAuthenticated } = useSnapshot(store.auth)

  const { isLoading } = useQuery('account', () => account.getAccount(), {
    onSuccess: (data) => {
      if (data) {
        store.auth.isAuthenticated = true
        store.user = data?.data
      }
    },
    retry: false,
    cacheTime: 600000,  
  })

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading])

  if (isLoading) return 'Loading...'

  return <Layouts />
}

export default App

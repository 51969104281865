import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Stack, Typography, IconButton, Button, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import drivers from '../../endpoints/drivers'
import { queryClient } from '../../queryClient'

function Form({ driver, toggleDrawer, closeDrawer }) {
  const isEditMode = driver !== 'new'

  const {
    handleSubmit,
    formState: { errors },
    register,
    setFocus,
  } = useForm({
    defaultValues: {
      name: driver?.name || '',
      surname: driver?.surname || '',
      middle_name: driver?.middle_name || '',
      phone_number: driver?.phone_number || '',
    },
  })

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const { mutate, isLoading } = useMutation(
    (data) =>
      isEditMode ? drivers.updateDriver(data) : drivers.createDriver(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('drivers')
        closeDrawer()
      },
      onError: () => {
        alert('Ошибка')
      },
    }
  )

  const onSubmit = (data) => {
    if (isEditMode) {
      mutate({ ...data, id: driver.id })
    } else {
      mutate({ ...data })
    }
  }

  return (
    <Stack width={650} spacing={6} p={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">
          {isEditMode ? 'Редактирование водителя' : 'Создание водителя'}
        </Typography>

        <IconButton aria-label="close" onClick={toggleDrawer(false, null)}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <TextField
            {...register('name', {
              required: 'Обязательное поле',
              minLength: { value: 3, message: 'Минимальная длина 3 символа' },
              maxLength: {
                value: 25,
                message: 'Минимальная длина 25 символов',
              },
            })}
            label="Имя"
            error={errors.name}
            helperText={errors.name?.message}
          />

          <TextField
            {...register('surname', {
              required: 'Обязательное поле',
              minLength: { value: 3, message: 'Минимальная длина 3 символа' },
              maxLength: {
                value: 25,
                message: 'Минимальная длина 25 символов',
              },
            })}
            label="Фамилия"
            error={errors.surname}
            helperText={errors.surname?.message}
          />

          <TextField
            {...register('middle_name', {
              required: 'Обязательное поле',
              minLength: { value: 3, message: 'Минимальная длина 3 символа' },
              maxLength: {
                value: 25,
                message: 'Максимальная длина 25 символов',
              },
            })}
            label="Oтчество"
            error={errors.middle_name}
            helperText={errors.middle_name?.message}
          />

          {!isEditMode && (
            <TextField
              {...register('password', {
                required: 'Обязательное поле',
                minLength: {
                  value: 6,
                  message: 'Минимальная длина 6 символов',
                },
                maxLength: {
                  value: 24,
                  message: 'Максимальная длина 24 символов',
                },
              })}
              label="Пароль"
              error={errors.password}
              helperText={errors.password?.message}
            />
          )}

          <TextField
            {...register('phone_number', {
              required: 'Обязательное поле',
              minLength: {
                value: 10,
                message: 'Минимальная длина 10 символов',
              },
              maxLength: {
                value: 10,
                message: 'Максимальная длина 10 символов',
              },
              pattern: {
                value: /^[0-9]+$/,
                message: 'Номер телефона должен состоять из цифр',
              },
            })}
            type="text"
            label="Номер телефона"
            error={errors.phone_number}
            helperText={errors.phone_number?.message}
          />

          <Button type="submit" disabled={isLoading}>
            {isEditMode ? 'Сохранит' : 'Добавить'}
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}

export default Form

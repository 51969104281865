import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Stack,
  Stepper,
  Typography,
  StepLabel,
  Step,
  LinearProgress,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import applications from '../../endpoints/applications'
import ApplicationInfo from './ApplicationInfo'
import SelectPassengers from './Step1/SelectPassengers'
import TimeSliderDialog from './TimeSliderDialog'
import ChooseTransport from './Step2/ChooseTransport'
import ChoosePlaces from './Step3/ChoosePlaces'
import Confirmation from './Step4/Confirmation'

function DistributePassengers() {
  const navigate = useNavigate()

  const { applicationId } = useParams()

  const [activeStep, setActiveStep] = useState(0)

  const [selectedPassengers, setSelectedPassengers] = useState([])

  const [openedAlreadySlider, setOpenedAlreadySlider] = useState(false)

  const [openTimeSliderDialog, setOpenTimeSliderDialog] = useState(false)

  const [value, setValue] = useState([0, 0])

  const [selectedTransports, setSelectedTransports] = useState([])

  const [distributedPassengers, setDistributedPassengers] = useState([])

  useEffect(() => {
    if (activeStep === 1) {
      setSelectedTransports([])
    }
  }, [activeStep])

  const {
    data: application,
    isError: applicationError,
    isLoading: applicationLoading,
  } = useQuery(
    `application/${applicationId}`,
    () => applications.getApplicationById(applicationId),
    { enabled: !!applicationId }
  )

  const { passengers } = application?.data || {}

  const notDistributedPassengers = useMemo(() => {
    return passengers?.filter((passenger) => passenger.processed === false)
  }, [passengers])

  const handleToggleTimeSliderDialog = (modalState) => {
    setOpenTimeSliderDialog(modalState)
  }

  function handleStepBack() {
    setActiveStep((v) => v - 1)
  }

  function handleNextStep() {
    setActiveStep((v) => v + 1)
  }

  function handleStepOrOpenTimeSlider() {
    openedAlreadySlider ? handleNextStep() : handleToggleTimeSliderDialog(true)
  }

  if (applicationLoading) {
    return <LinearProgress />
  }

  if (applicationError) {
    return 'Ошибка загрузки заявки'
  }

  return (
    <Stack spacing={1.5}>
      <Typography variant="h3">
        <ArrowBackIcon
          fontSize="large"
          onClick={() => navigate(`/applications/${applicationId}`)}
          sx={{ cursor: 'pointer' }}
        />
        Распределить пассажиров
      </Typography>

      <ApplicationInfo applicationData={application?.data} />

      <Stack spacing={5}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>
              <Typography variant="body2">Шаг №1</Typography>
              <Typography>Выбрать пассажиров</Typography>
            </StepLabel>
          </Step>

          <Step>
            <StepLabel>
              <Typography variant="body2">Шаг №2</Typography>
              <Typography>Выбрать транспорт</Typography>
            </StepLabel>
          </Step>

          <Step>
            <StepLabel>
              <Typography variant="body2">Шаг №3</Typography>
              <Typography>Выбрать места</Typography>
            </StepLabel>
          </Step>

          <Step>
            <StepLabel>
              <Typography variant="body2">Шаг №4</Typography>
              <Typography>Подтверждение</Typography>
            </StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <SelectPassengers
            passengers={notDistributedPassengers}
            selectedPassengers={selectedPassengers}
            setSelectedPassengers={setSelectedPassengers}
            applicationId={applicationId}
            handleStepOrOpenTimeSlider={handleStepOrOpenTimeSlider}
          />
        )}

        {activeStep === 1 && (
          <ChooseTransport
            selectedPassengers={selectedPassengers}
            value={value}
            setValue={setValue}
            actionDate={application?.data.action_date}
            setSelectedTransports={setSelectedTransports}
            selectedTransports={selectedTransports}
            handleNextStep={handleNextStep}
            handleStepBack={handleStepBack}
          />
        )}

        {activeStep === 2 && (
          <ChoosePlaces
            selectedPassengers={selectedPassengers}
            selectedTransports={selectedTransports}
            distributedPassengers={distributedPassengers}
            setDistributedPassengers={setDistributedPassengers}
            handleNextStep={handleNextStep}
            handleStepBack={handleStepBack}
            value={value}
            actionDate={application?.data.action_date}
          />
        )}

        {activeStep === 3 && (
          <Confirmation
            distributedPassengers={distributedPassengers}
            setDistributedPassengers={setDistributedPassengers}
            application={application?.data}
            value={value}
            handleStepBack={handleStepBack}
          />
        )}
      </Stack>

      <TimeSliderDialog
        open={openTimeSliderDialog}
        handleToggleTimeSliderDialog={handleToggleTimeSliderDialog}
        handleNext={handleNextStep}
        applicationData={application?.data}
        setOpenedAlreadySlider={setOpenedAlreadySlider}
        value={value}
        setValue={setValue}
      />
    </Stack>
  )
}

export default DistributePassengers

import axios from 'axios'
import { store } from './store'

export const apiService = axios.create({
  baseURL: process.env.REACT_APP_DEV_MODE,
})

apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
})

apiService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('token')

      store.auth.isAuthenticated = false
    }
    return Promise.reject(error)
  }
)

import { useState } from 'react'
import { useQuery } from 'react-query'
import {
  IconButton,
  Tab,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Typography,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { Details } from './Details'
import { Passengers } from './Passengers'
import tasks from '../../../endpoints/tasks'

const TaskModal = ({ open, handleToggleModal, taskId }) => {
  const [currentTab, setCurrentTab] = useState('details')

  const {
    data: task,
    isError: isTaskError,
    isLoading: isTaskLoading,
  } = useQuery(`tasks/${taskId}`, () => tasks.getTask(taskId), {
    enabled: !!taskId,
    retry: 1,
    refetchOnWindowFocus: false,
  })

  const taskData = task?.data

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  if (isTaskLoading) {
    return (
      <Backdrop
        open={isTaskLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleToggleModal(false, null)}
      maxWidth="md"
      scroll="paper"
      fullWidth={true}
    >
      <IconButton
        onClick={() => handleToggleModal(false, null)}
        sx={{
          position: 'absolute',
          top: '24px',
          right: '24px',
          width: '32px',
          height: '32px',
        }}
      >
        <HighlightOffIcon sx={{ width: '32px', height: '32px' }} />
      </IconButton>
      {isTaskError ? (
        <Typography py={10} textAlign="center" variant="h5">
          Ошибка при загрузке заявки ...
        </Typography>
      ) : (
        <>
          <DialogTitle>Задание №{taskId}</DialogTitle>

          <DialogContent>
            <TabContext value={currentTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Детали задании" value="details" />

                  <Tab label="Пассажиры" value="passengers" />
                </TabList>
              </Box>

              <TabPanel value="details" sx={{ px: 0 }}>
                <Details task={taskData} />
              </TabPanel>

              <TabPanel value="passengers" sx={{ px: 0 }}>
                <Passengers
                  passengers={taskData?.passenger_application_pairs}
                />
              </TabPanel>
            </TabContext>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default TaskModal

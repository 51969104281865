import { useEffect, useState } from 'react'
import {
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonGroup,
} from '@mui/material'

import { buttonData } from './../../../enums'

const Passengers = ({ passengers }) => {
  const [currentTab, setCurrentTab] = useState('all')

  const [filteredPassengers, setFilteredPassengers] = useState(passengers)

  const tabCounts = {
    all: passengers.length,
    distributed: passengers.filter((passenger) => passenger.processed).length,
    notDistributed: passengers.filter((passenger) => !passenger.processed)
      .length,
  }

  useEffect(() => {
    const allPassengers = passengers || []
    const distributedPassengers = allPassengers.filter(
      (passenger) => passenger.processed
    )
    const notDistributedPassengers = allPassengers.filter(
      (passenger) => !passenger.processed
    )

    setFilteredPassengers(
      currentTab === 'all'
        ? allPassengers
        : currentTab === 'distributed'
        ? distributedPassengers
        : notDistributedPassengers
    )
  }, [currentTab, passengers])

  return (
    <Stack spacing={3.5}>
      <Stack direction="row" spacing={2}>
        <ButtonGroup color="secondary">
          {buttonData.map((button) => (
            <Button
              key={button.label}
              onClick={() => setCurrentTab(button.value)}
              color={currentTab === button.value ? 'primary' : 'secondary'}
            >
              {button.label} {tabCounts[button.value]}
            </Button>
          ))}
        </ButtonGroup>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ opacity: 0.5 }}>
              <TableCell>№</TableCell>
              <TableCell>ФИО</TableCell>
              <TableCell>Транспорт</TableCell>
              <TableCell>Место</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPassengers.map((passenger, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="passenger">
                    {index + 1}
                  </TableCell>

                  <TableCell>{passenger.full_name}</TableCell>

                  <TableCell>{passenger?.vehicle_info}</TableCell>

                  <TableCell>
                    {passenger?.seat_number && '№' + passenger?.seat_number}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default Passengers

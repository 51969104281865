import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import GroupIcon from '@mui/icons-material/Group'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import { formatDate, dateTimeFormat } from '../../../utils/dateUtils'
import tasks from '../../../endpoints/tasks'

const Сonfirmation = ({
  distributedPassengers,
  application,
  setDistributedPassengers,
  handleStepBack,
}) => {
  const navigate = useNavigate()

  const [completedCount, setCompletedCount] = useState(0)
  const [errorCount, setErrorCount] = useState(0)

  const [disabled, setDisabled] = useState(false)

  const handleClick = async () => {
    const updatedPassengers = distributedPassengers.map((item) => ({
      ...item,
      status: 'loading',
      message: '',
    }))

    setDistributedPassengers(updatedPassengers)

    for (let updatedPassenger of updatedPassengers) {
      try {
        setDisabled(true)
        await tasks.createTasks({
          application_id: application?.id,
          action_start_time: formatDate(updatedPassenger.startTime).toISO(),
          action_end_time: formatDate(updatedPassenger.endTime).toISO(),
          vehicle_id: updatedPassenger.id,
          task_id: updatedPassenger.suitable_task
            ? updatedPassenger.suitable_task.id
            : null,
          passenger_seat_pairs: updatedPassenger.passenger_seat_pairs,
          notes: updatedPassenger.notes,
        })

        updatedPassenger.status = 'completed'
        setCompletedCount((prevCount) => prevCount + 1)
        setDistributedPassengers((prevState) => [...prevState])
      } catch (err) {
        console.log(err)
        updatedPassenger.status = 'error'
        updatedPassenger.message = err.response.data.message
        setErrorCount((prevCount) => prevCount + 1)
        setDistributedPassengers((prevState) => [...prevState])
      }
    }
  }

  const handleBack = () => {
    handleStepBack()
    setDistributedPassengers([])
  }

  return (
    <Stack spacing={1.5}>
      {distributedPassengers?.map((item) => {
        return (
          <Stack
            key={item.id}
            direction="row"
            justifyContent="space-between"
            bgcolor="#FFFFFF"
            p={2}
            borderRadius="5px"
            alignItems="center"
          >
            <Stack width="50%">
              <Typography variant="subtitle2">
                {application?.from_point?.name} - {application?.to_point?.name}
              </Typography>

              <Stack direction="row" spacing={1.5}>
                <Typography display="flex" alignItems="center">
                  <GroupIcon sx={{ mr: 0.5 }} />

                  {item?.passenger_seat_pairs?.length}
                </Typography>

                <Typography display="flex" alignItems="center">
                  <AccountCircleIcon sx={{ mr: 0.5 }} />
                  {item?.current_driver?.surname}{' '}
                  {item?.current_driver?.name[0]}.{' '}
                  {/*{item.current_driver?.middle_name[0]}. */}
                </Typography>

                <Typography>
                  {item?.mark?.name} {item?.model?.name} {item?.year} /{' '}
                  {item?.state_number}
                </Typography>
              </Stack>
            </Stack>

            <Stack width="20%">
              <Typography variant="subtitle2">
                {dateTimeFormat(item.startTime)} -{' '}
                {dateTimeFormat(item.endTime)}
              </Typography>
            </Stack>

            <Stack width="30%" textAlign="center" sx={{ display: 'inline' }}>
              {item.status === 'loading' && (
                <Typography
                  sx={{
                    bgcolor: 'lightgrey',
                    p: '4px 8px',
                    borderRadius: '5px',
                    display: 'inline',
                  }}
                >
                  Идет создание...
                </Typography>
              )}

              {item.status === 'completed' && (
                <Typography
                  sx={{
                    bgcolor: 'green',
                    p: '4px 8px',
                    borderRadius: '5px',
                    display: 'inline',
                  }}
                >
                  Готово
                </Typography>
              )}

              {item.status === 'error' && (
                <Stack textAlign="center" sx={{ display: 'inline' }}>
                  <Typography
                    sx={{
                      display: 'inline',
                      bgcolor: 'red',
                      p: '2px 4px',
                      borderRadius: '5px',
                    }}
                  >
                    Ошибка
                  </Typography>

                  <Typography>{item.message}</Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        )
      })}

      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Button
          fullWidth
          color="secondary"
          onClick={handleBack}
          disabled={
            disabled ||
            completedCount + errorCount === distributedPassengers.length
          }
        >
          Назад
        </Button>

        {completedCount + errorCount !== distributedPassengers.length &&
        distributedPassengers.length !== 0 ? (
          <Button fullWidth onClick={handleClick} disabled={disabled}>
            Создать и обновить задания
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={() => navigate(`/applications/${application?.id}`)}
          >
            Перейти к странице просмотра заявки
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default Сonfirmation

import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { dateTimeFormat } from '../../utils/dateUtils'
import { taskStatuses } from '../../enums'

export function TaskListByDrivers({ drivers, handleToggleModal }) {
  return (
    <Stack>
      <Stack direction="row" p={2}>
        <Typography width="220px">Водитель</Typography>
        <Typography width="220px">Транспорт</Typography>
        <Typography width="120px">Всего заданий</Typography>
        <Typography width="120px" textAlign="center">
          Выпол.
        </Typography>
        <Typography width="120px" textAlign="center">
          В процес.
        </Typography>
        <Typography width="120px" textAlign="center">
          Отмен.
        </Typography>
        <Typography width="150px" textAlign="center">
          СТАТУС
        </Typography>
      </Stack>

      {drivers.length === 0 ? (
        <Typography textAlign="center" my={8}>
          Пусто
        </Typography>
      ) : (
        <Stack spacing={1.5}>
          {drivers?.map((driver) => {
            const driverDailyStatus =
              driver?.daily_status === 'online'
                ? 'Онлайн'
                : 'offline'
                ? 'Оффлайн'
                : 'Перерыв'

            return (
              <Accordion key={driver.id} sx={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack direction="row" alignItems="center">
                    <Typography width="220px">
                      {driver?.surname} {driver?.name[0]}
                      {'. '}
                      {driver?.middle_name && driver?.middle_name[0] + '.'}
                    </Typography>

                    <Stack width="220px">
                      <Typography variant="subtitle2">
                        {driver?.vehicle?.mark?.name}{' '}
                        {driver?.vehicle?.model?.name} {driver?.vehicle?.year}
                      </Typography>

                      <Typography variant="body2" sx={{ opacity: 0.5 }}>
                        {driver?.vehicle?.state_number}
                        {', '} {driver?.vehicle?.seat_count} мест
                      </Typography>
                    </Stack>

                    <Typography width="120px" textAlign="center">
                      {driver?.tasks?.length}
                    </Typography>

                    <Typography width="120px" textAlign="center">
                      {
                        driver?.tasks?.filter((t) => t.status === 'completed')
                          ?.length
                      }
                    </Typography>

                    <Typography width="120px" textAlign="center">
                      {
                        driver?.tasks?.filter((t) => t.status === 'on_process')
                          ?.length
                      }
                    </Typography>

                    <Typography width="120px" textAlign="center">
                      {
                        driver?.tasks?.filter((t) => t.status === 'rejected')
                          ?.length
                      }
                    </Typography>

                    <Typography
                      width="150px"
                      textAlign="center"
                      sx={{
                        backgroundColor: '#F0F0F0',
                        padding: '6px',
                        borderRadius: '16px',
                        display: 'inline',
                      }}
                    >
                      {driverDailyStatus}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {drivers?.tasks?.length === 0 ? (
                    <Typography textAlign="center" my={3}>
                      Пусто
                    </Typography>
                  ) : (
                    <TableContainer>
                      <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
                        <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                          <TableRow>
                            <TableCell>ЗАДАНИЕ</TableCell>
                            <TableCell>ПЛАН. ВРЕМЯ</TableCell>
                            <TableCell>ФАКТ. ВРЕМЯ</TableCell>
                            <TableCell align="center">СТАТУС</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {driver?.tasks?.map((task) => {
                            const taskStatus = taskStatuses.find(
                              (item) => item.value === task?.status
                            )
                            return (
                              <TableRow key={task?.id}>
                                <TableCell component="th" scope="application">
                                  <Typography variant="subtitle2">
                                    {task?.from_point.name} -{' '}
                                    {task?.to_point.name}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    sx={{ opacity: 0.5 }}
                                  >
                                    №{task?.id}, {task?.passenger_count} чел.
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  {dateTimeFormat(task?.action_start_time)} -{' '}
                                  {dateTimeFormat(task?.action_end_time)}
                                </TableCell>

                                <TableCell>
                                  {task?.actual_start_time &&
                                    task?.actual_end_time &&
                                    dateTimeFormat(task?.actual_start_time) +
                                      ' - ' +
                                      dateTimeFormat(task?.actual_end_time)}
                                </TableCell>

                                <TableCell align="center">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      backgroundColor: `${taskStatus?.bgcolor}`,
                                      color: `${taskStatus?.color}`,
                                      padding: '6px 12px',
                                      borderRadius: '16px',
                                      display: 'inline',
                                    }}
                                  >
                                    {taskStatus?.label}
                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <img
                                    alt="Arrow right icon"
                                    src={require(`../../assets/images/ArrorRight.png`)}
                                    onClick={() =>
                                      handleToggleModal(true, task?.id)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Stack>
      )}
    </Stack>
  )
}

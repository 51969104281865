import { useState, useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import debounce from 'lodash.debounce'
import {
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ru'

import applications from '../../endpoints/applications'
import points from '../../endpoints/points'
import { extendedStatuses, route_points } from '../../enums'
import CustomPagination from '../../components/ui/Pagination'
import SearchInput from '../../components/ui/SearchInput'
import ApplicationModal from './ApplicationModal/index'
import TableSkeleton from '../../components/ui/TableSkeleton'
import ApplicationItem from './ApplicationItem'
import Autocomplete from '../../components/ui/AutocompleteOfList'

const List = ({ currentTab }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const [pageCount, setPageCount] = useState(1)

  const [status, setStatus] = useState('all')

  const [date, setDate] = useState(null)

  const [search, setSearch] = useState('')

  const [fromPointId, setFromPointId] = useState(null)

  const [toPointId, setToPointId] = useState(null)

  const [openModalWindow, setOpenModalWindow] = useState(false)

  const [applicationId, setApplicationId] = useState(null)

  const [firstLoad, setFirstLoad] = useState(true)

  const [sort_key, setSort_key] = useState('created_at')

  useEffect(() => {
    setCurrentPage(1)
  }, [currentTab])

  const handleToggleModal = (modalState) => {
    setOpenModalWindow(modalState)
  }

  const {
    data: allPoints,
    isLoading: allPointsLoading,
    isError: allPointsError,
  } = useQuery('points/many/selection', () => points.getAllPoints(), {
    retry: 3,
  })

  const allPointsOptions = useMemo(() => {
    return Array.isArray(allPoints?.data)
      ? allPoints?.data.map((point) => ({
          id: point.id,
          label: point.name,
        }))
      : []
  }, [allPoints])

  const {
    data: applicationsData,
    isLoading: isApplicationsLoading,
    isError: isApplicationsError,
  } = useQuery(
    [
      `applications/many/${currentTab}`,
      currentPage,
      status,
      date,
      search,
      fromPointId,
      toPointId,
      currentTab,
      sort_key,
    ],
    () =>
      applications.getApplications({
        page: currentPage,
        status: status === 'all' ? null : `${status}`,
        from_date: date && dayjs(`${date}`).format('YYYY-MM-DD'),
        to_date: date && dayjs(`${date}`).format('YYYY-MM-DD'),
        from_point_id: fromPointId?.id,
        to_point_id: toPointId?.id,
        keyword: search,
        view_type: currentTab,
        sort_key,
      }),
    {
      onSuccess: (data) => {
        setPageCount(Math.ceil(data?.data?.total / data?.data?.limit))
        setFirstLoad(false)
      },
      cacheTime: 0,
    }
  )

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const debouncedSearch = useMemo(() => {
    return debounce((e) => {
      setSearch(e.target.value)
      setCurrentPage(1)
    }, 1000)
  }, [])

  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }

  if (isApplicationsError) {
    return 'Ошибка при загрузке данных'
  }

  return (
    <Stack spacing={3}>
      <Stack flexDirection="row">
        <FormControl
          sx={{
            mr: '12px',
            '.MuiOutlinedInput-notchedOutline': { border: '1px solid #FFFFFF' },
          }}
        >
          <InputLabel>Сортировка</InputLabel>

          <Select
            value={sort_key}
            label="Сортировка"
            onChange={(e) => setSort_key(e.target.value)}
          >
            <MenuItem value="created_at">По дате выезда</MenuItem>

            <MenuItem value="action_date">По дате создание</MenuItem>
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DatePicker
            label="Дата поездки"
            value={date}
            onChange={(newValue) => setDate(newValue)}
            slotProps={{
              textField: { size: 'small' },
              actionBar: {
                actions: ['clear'],
              },
            }}
            sx={{ mr: 1.5, bgcolor: 'white', borderRadius: '4px', width: 160 }}
          />
        </LocalizationProvider>

        {allPointsOptions !== undefined &&
          route_points.map((point) => (
            <Autocomplete
              key={point.label}
              point={point}
              fromPointId={fromPointId}
              toPointId={toPointId}
              setFromPointId={setFromPointId}
              setToPointId={setToPointId}
              allPointsOptions={allPointsOptions}
              allPointsLoading={allPointsLoading}
              allPointsError={allPointsError}
            />
          ))}

        <FormControl
          sx={{
            mr: '12px',
            '.MuiOutlinedInput-notchedOutline': { border: '1px solid #FFFFFF' },
          }}
        >
          <InputLabel>Статус</InputLabel>

          <Select value={status} label="Статусы" onChange={handleChangeStatus}>
            <MenuItem value="all">Все</MenuItem>

            {extendedStatuses.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <SearchInput
          minWidth={230}
          onChange={debouncedSearch}
          placeholder="Поиск по ID и по ФИО"
          sx={{ marginLeft: 'auto', width: '270px' }}
        />
      </Stack>

      {isApplicationsLoading && firstLoad ? (
        <LinearProgress />
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
              <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Источник</TableCell>
                  <TableCell>Маршрут</TableCell>
                  <TableCell>Дата поездки</TableCell>
                  <TableCell align="center">Пассажиры</TableCell>
                  <TableCell align="center">Статус</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {isApplicationsLoading ? (
                <TableSkeleton rowsNum={10} columnsNum={7} />
              ) : (
                <TableBody>
                  {applicationsData?.data?.data?.length === 0 ? (
                    <Typography textAlign="center" my={3}>
                      Пусто
                    </Typography>
                  ) : (
                    applicationsData?.data?.data?.map((application) => {
                      return (
                        <ApplicationItem
                          key={application.id}
                          application={application}
                          setApplicationId={setApplicationId}
                          handleToggleModal={handleToggleModal}
                        />
                      )
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {applicationsData?.data?.data?.length !== 0 && (
            <CustomPagination
              count={pageCount}
              page={currentPage}
              handleChangePage={handleChangePage}
            />
          )}
        </>
      )}

      <ApplicationModal
        open={openModalWindow}
        handleToggleModal={handleToggleModal}
        applicationId={applicationId}
        currentTab={currentTab}
      />
    </Stack>
  )
}

export default List

import { useState, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import groupBy from 'lodash/groupBy'
import {
  Stack,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Box,
  CircularProgress,
  Backdrop,
  useTheme,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import applications from '../../endpoints/applications'
import points from '../../endpoints/points'
import { route_points } from '../../enums'
import { queryClient } from '../../queryClient'
import example from './../../assets/files/example.xlsx'

const CreateApplication = () => {
  const navigate = useNavigate()

  const { common } = useTheme()

  const [excelLoading, setExcelLoading] = useState(false)

  const [date, setDate] = useState(null)

  const [time, setTime] = useState(null)

  const [toPointId, setToPointId] = useState(null)

  const [fromPointId, setFromPointId] = useState(null)

  const [routeError, setRouteError] = useState(false)

  const [isEmptyPassengers, setIsEmptyPassengers] = useState(true)

  const fullNameInputRef = useRef(null)

  const autoscrollRef = useRef(null)

  const { handleSubmit, register, clearErrors } = useForm()

  const {
    data: allPoints,
    isError: allPointsError,
    isLoading: allPointsLoading,
  } = useQuery('points/many/selection', () => points.getAllPoints(), {
    retry: 3,
  })

  const { mutate: createApplication, isLoading: isCreateLoading } = useMutation(
    (data) => applications.createApplication(data),
    {
      onSuccess: (data) => {
        alert(
          `Заявка успешно создана. Количества пассажиров: ${data?.data?.passengers?.length}`
        )
        queryClient.invalidateQueries('applications/many/active')
        navigate(`/applications/${data.data.id}`)
      },

      onError: (err) => {
        if (err?.response?.data?.statusCode === 400) {
          alert(err?.response?.data?.message)
        } else {
          alert('Ошибка при создании заявки')
        }
      },

      onSettled: () => {
        setIsEmptyPassengers(true)
      },
    }
  )

  useEffect(() => {
    if (
      fromPointId !== null &&
      toPointId !== null &&
      toPointId.id === fromPointId.id
    ) {
      setRouteError(true)
    } else {
      setRouteError(false)
    }
  }, [toPointId, fromPointId])

  const allPointsOptions = useMemo(() => {
    return allPoints?.data?.map((point) => ({
      id: point.id,
      label: point.name,
    }))
  }, [allPoints])

  const [passengers, setPassengers] = useState([
    { full_name: '', iin: '', position: '' },
  ])

  const handleAddPassenger = () => {
    setPassengers([...passengers, { full_name: '', iin: '', position: '' }])
    setTimeout(() => {
      fullNameInputRef.current.focus()
      if (autoscrollRef.current) {
        const scrollContainer = autoscrollRef.current
        scrollContainer.scrollTo({
          behavior: 'smooth',
          top: scrollContainer.scrollHeight,
        })
      }
    }, 100)
  }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target

    const updatedPassengers = [...passengers]
    updatedPassengers[index][name] = value
    setPassengers(updatedPassengers)
  }

  const onSubmit = () => {
    const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
    const action_date =
      dayjs(date).format('YYYY-MM-DD') + ' ' + dayjs(time).format('HH:mm:ss')

    const isActionDateValid = dayjs(action_date).isAfter(currentDateTime)

    const from_point_id = fromPointId.id
    const to_point_id = toPointId.id

    const hasEmptyData = passengers.some(
      (passenger) =>
        passenger.full_name.trim() === '' || passenger.iin.trim() === ''
    )

    if (hasEmptyData) {
      alert('Пожалуйста, заполните все поля ФИО и ИИН для каждого пассажира')
      return
    }

    const groupedByIIN = groupBy(passengers, 'iin')
    const duplicateIINs = Object.values(groupedByIIN).filter(
      (group) => group.length > 1
    )

    if (duplicateIINs.length > 0) {
      let alertMessage = 'Найдены пассажиры с одинаковыми ИИН!!!\n'

      duplicateIINs.forEach((group) => {
        const duplicateFullNames = group.map((passenger) => passenger.full_name)

        const passengerIndices = group.map(
          (passenger) => passengers.indexOf(passenger) + 1
        )

        const fullNameList = duplicateFullNames
          .map((fullName, index) => `${passengerIndices[index]}. ${fullName}`)
          .join(', ')

        alertMessage += `ИИН: ${group[0].iin}\n${fullNameList}\n`
      })

      alert(alertMessage)
      return
    }

    if (isActionDateValid) {
      if (from_point_id !== to_point_id) {
        createApplication({
          passengers,
          action_date,
          from_point_id,
          to_point_id,
        })
      } else {
        alert('Выберите разные пункты отправления и назначения')
      }
    } else {
      alert('Выберите дату и время позже текущего времени')
    }
  }

  const handleRemovePassenger = (index) => {
    const list = [...passengers]
    list.splice(index, 1)
    setPassengers(list)
    if (passengers.length === 1) {
      setPassengers([{ full_name: '', iin: '', position: '' }])
    }
  }

  const handleUpload = async (event) => {
    setExcelLoading(true)

    try {
      const file = event.target.files?.[0]
      const formData = new FormData()
      formData.append('file', file)
      const response = await applications.uploadDataFromExcel(formData)
      setPassengers([...passengers, ...response.data])
    } catch (error) {
      alert('Ошибка при загрузке файла')
    } finally {
      setExcelLoading(false)
    }
  }

  useEffect(() => {
    if (
      passengers.length === 1 &&
      isEqual(passengers[0], { full_name: '', iin: '', position: '' })
    ) {
      setIsEmptyPassengers(true)
    } else {
      setIsEmptyPassengers(false)
    }
  }, [passengers])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography variant="h3">Новая заявка</Typography>

        <Stack
          direction="column"
          spacing={3}
          bgcolor={common.colorBgDefault}
          p="24px"
          borderRadius="8px"
        >
          <Typography variant="h5">Основная информация</Typography>

          <Stack direction="row" spacing={2} justifyContent="space-between">
            {allPointsOptions !== undefined &&
              route_points.map((point) => (
                <Autocomplete
                  fullWidth
                  key={point.label}
                  size="small"
                  disablePortal
                  options={allPointsOptions}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={allPointsLoading || allPointsError}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={point.label}
                      {...register(
                        point.label === 'Откуда' ? 'from_point' : 'to_point',
                        { required: 'Это поле обязательно' }
                      )}
                      error={allPointsError || routeError}
                      helperText={
                        allPointsLoading
                          ? 'Загрузка данных...'
                          : allPointsError
                          ? 'Ошибка ...'
                          : routeError
                          ? 'Выберите разные пункты'
                          : ''
                      }
                      disabled={allPointsLoading || allPointsError}
                      sx={{
                        bgcolor: '#FFFFFF',
                        borderRadius: '4px',
                        ...(allPointsLoading && { opacity: 0.5 }),
                        ...(allPointsError && {
                          borderColor: 'red',
                        }),
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    clearErrors(
                      point.label === 'Откуда' ? 'from_point' : 'to_point'
                    )
                    point.label === 'Откуда'
                      ? setFromPointId(newValue)
                      : setToPointId(newValue)
                  }}
                />
              ))}

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DatePicker
                label="Дата поездки"
                value={date}
                disablePast
                onChange={(newValue) => setDate(newValue)}
                slotProps={{
                  textField: { size: 'small', fullWidth: true },
                  actionBar: { actions: ['clear'] },
                }}
              />

              <TimeField
                fullWidth
                label="Время поездки"
                value={time}
                onChange={(newValue) => setTime(newValue)}
                format="HH:mm"
                size="small"
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          spacing={3}
          bgcolor={common.colorBgDefault}
          p="24px"
          borderRadius="8px"
        >
          <Typography variant="h5">Пассажиры</Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            p="12px 50px 0px 0px"
            alignItems="center"
          >
            <Typography
              variant="body2"
              color={common.colorTypoSecondary}
              width={35}
            >
              №
            </Typography>

            <Typography
              width="50%"
              variant="body2"
              color={common.colorTypoSecondary}
            >
              ФИО
            </Typography>

            <Typography
              width="50%"
              variant="body2"
              color={common.colorTypoSecondary}
            >
              ИИН
            </Typography>
          </Stack>

          <Stack
            ref={autoscrollRef}
            spacing={2}
            maxHeight="330px"
            sx={{
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '7px',
              },
            }}
          >
            {passengers.map((passenger, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                pr={1}
              >
                <Typography width={60}>{index + 1}</Typography>

                <TextField
                  fullWidth
                  size="small"
                  name="full_name"
                  value={passenger.full_name}
                  onChange={(event) => handleInputChange(index, event)}
                  inputRef={
                    index === passengers.length - 1 ? fullNameInputRef : null
                  }
                />

                <TextField
                  fullWidth
                  size="small"
                  name="iin"
                  value={passenger.iin}
                  onChange={(event) => handleInputChange(index, event)}
                />

                <DeleteOutlineIcon
                  onClick={() => {
                    handleRemovePassenger(index)
                  }}
                  sx={{
                    cursor: isEmptyPassengers ? 'not-allowed' : 'pointer',
                    color: isEmptyPassengers ? 'grey' : 'red',
                  }}
                />
              </Stack>
            ))}
          </Stack>

          <Stack direction="row" spacing={1.5} alignItems="center">
            <Button onClick={handleAddPassenger}>Добавить пассажира</Button>

            <Box sx={{ m: 1, position: 'relative' }}>
              <Button component="label" disabled={excelLoading}>
                Загрузить список с Excel
                <input
                  hidden
                  accept="excel/*"
                  type="file"
                  onChange={handleUpload}
                />
              </Button>

              {excelLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'green',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>

            <a
              href={example}
              target="_blank"
              rel="noopener noreferrer"
              download="example.xlsx"
            >
              Скачать пример
            </a>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button type="submit" disabled={isCreateLoading}>
            Сохранить
          </Button>
        </Stack>

        <Backdrop
          open={isCreateLoading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: '#fff',
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Stack>
    </form>
  )
}

export default CreateApplication

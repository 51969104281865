import { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash.debounce'
import {
  Stack,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Drawer,
} from '@mui/material'

import driverActions from '../../endpoints/drivers'
import TableSkeleton from '../../components/ui/TableSkeleton'
import CustomPagination from '../../components/ui/Pagination'
import SearchInput from '../../components/ui/SearchInput'
import DriverItem from './DriverItem'
import Form from './Form'

const Drivers = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const [pageCount, setPageCount] = useState(1)

  const [search, setSearch] = useState('')

  const [firstLoad, setFirstLoad] = useState(true)

  const [openDrawer, setOpenDrawer] = useState(false)

  const [driver, setDriver] = useState(null)

  const {
    data: driversData,
    isLoading: isDriversLoading,
    isError: isDriversError,
  } = useQuery(
    ['drivers', search, currentPage],
    () => driverActions.getAllDrivers({ page: currentPage, keyword: search }),
    {
      onSuccess: (data) => {
        setPageCount(Math.ceil(data?.data?.total / data?.data?.limit))
        setFirstLoad(false)
      },
    }
  )

  const drivers = useMemo(() => driversData?.data?.data, [driversData])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const debouncedSearch = useMemo(() => {
    return debounce((e) => {
      setSearch(e.target.value)
      setCurrentPage(1)
    }, 1000)
  }, [])

  const toggleDrawer = (modalState, driver) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpenDrawer(modalState)
    setDriver(driver)
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  if (isDriversError) {
    return 'Ошибка при загрузке данных'
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Водители</Typography>

        <Button onClick={toggleDrawer(true, 'new')}>Добавить водитель</Button>
      </Stack>

      <SearchInput
        minWidth={230}
        onChange={debouncedSearch}
        placeholder="Поиск..."
        sx={{ width: '270px' }}
      />

      {isDriversLoading && firstLoad ? (
        <LinearProgress />
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 920, bgcolor: '#FFFFFF' }}>
              <TableHead bgcolor="#E9E9E9" sx={{ opacity: 0.5 }}>
                <TableRow>
                  <TableCell>ФИО</TableCell>
                  <TableCell>Автомобиль</TableCell>
                  <TableCell>Номер</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              {isDriversLoading ? (
                <TableSkeleton rowsNum={10} columnsNum={7} />
              ) : (
                <TableBody>
                  {drivers?.length === 0 ? (
                    <Typography textAlign="center" my={3}>
                      Пусто
                    </Typography>
                  ) : (
                    drivers?.map((driver) => {
                      return (
                        <DriverItem
                          key={driver.id}
                          driver={driver}
                          toggleDrawer={toggleDrawer}
                          drivers={drivers}
                        />
                      )
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <CustomPagination
            count={pageCount}
            page={currentPage}
            handleChangePage={handleChangePage}
          />

          <Drawer
            anchor={'right'}
            open={openDrawer}
            onClose={toggleDrawer(false, null)}
          >
            <Form
              driver={driver}
              toggleDrawer={toggleDrawer}
              closeDrawer={closeDrawer}
            />
          </Drawer>
        </>
      )}
    </Stack>
  )
}

export default Drivers

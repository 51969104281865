import { useState, useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash.debounce'
import dayjs from 'dayjs'
import {
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ru'

import Autocomplete from '../../components/ui/AutocompleteOfList'
import SearchInput from '../../components/ui/SearchInput'
import points from '../../endpoints/points'
import tasks from '../../endpoints/tasks'
import { route_points, taskStatuses } from '../../enums'
import { TaskListByStatus } from './TaskListByStatus'
import { TaskListByDrivers } from './TaskListByDrivers'
import TaskModal from './TaskDialogWindow'
import vehicles from '../../endpoints/vehicles'

const Monitoring = () => {
  const [date, setDate] = useState(dayjs())

  const [sortGroupBy, setSortGroupBy] = useState('byStatus')

  const [status, setStatus] = useState('all')

  const [fromPointId, setFromPointId] = useState(null)
  console.log('fromPointId: ', fromPointId)

  const [toPointId, setToPointId] = useState(null)
  console.log('toPointId: ', toPointId)

  const [search, setSearch] = useState('')

  const [openModalWindow, setOpenModalWindow] = useState(false)

  const [currentTaskId, setCurrentTaskId] = useState(null)

  const [activeTasks, setActiveTasks] = useState([])

  const [onProcessTasks, setOnProcessTasks] = useState([])

  const [completedTasks, setCompletedTasks] = useState([])

  const [rejectedTasks, setRejectedTasks] = useState([])

  const [driversWithTasks, setDriversWithTasks] = useState([])

  const [dailyInfo, setDailyInfo] = useState(null)

  const {
    data: allPoints,
    isLoading: allPointsLoading,
    isError: allPointsError,
  } = useQuery('points/many/selection', () => points.getAllPoints(), {
    retry: 3,
    cacheTime: 30000,
  })

  const allPointsOptions = useMemo(() => {
    return Array.isArray(allPoints?.data)
      ? allPoints?.data.map((point) => ({
          id: point.id,
          label: point.name,
        }))
      : []
  }, [allPoints])

  const {
    data: monitoringData,
    isLoading: isMonitoringLoading,
    isError: isMonitoringError,
  } = useQuery(
    [
      `tasks/monitoring/list`,
      date,
      sortGroupBy,
      status,
      fromPointId,
      toPointId,
      search,
    ],
    () =>
      tasks.getMonitoringTasks({
        from_date: date && dayjs(`${date}`).format('YYYY-MM-DD'),
        to_date: date && dayjs(`${date}`).format('YYYY-MM-DD'),
        status: status === 'all' ? null : status,
        from_point_id: fromPointId?.id,
        to_point_id: toPointId?.id,
        keyword: search,
      }),
    {
      onSuccess: (data) => {
        const tasks = data?.data || []

        setActiveTasks(tasks?.filter((t) => t.status === 'active'))
        setOnProcessTasks(tasks?.filter((t) => t.status === 'on_process'))
        setCompletedTasks(tasks?.filter((t) => t.status === 'completed'))
        setRejectedTasks(tasks?.filter((t) => t.status === 'rejected'))

        const driverMap = {}

        tasks?.forEach((task) => {
          const driverId = task.driver.id
          if (!driverMap[driverId]) {
            driverMap[driverId] = task.driver
            driverMap[driverId].vehicle = task.vehicle
            driverMap[driverId].tasks = [task]
          } else {
            driverMap[driverId].tasks.push(task)
          }
        })

        setDriversWithTasks(Object.values(driverMap))
      },
      retry: 3,
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (
      dayjs(`${date}`).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
    ) {
      vehicles
        .getDailyInfo()
        .then((res) => {
          setDailyInfo(res?.data)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setDailyInfo(null)
    }
  }, [date])

  const debouncedSearch = useMemo(() => {
    return debounce((e) => {
      setSearch(e.target.value)
    }, 1000)
  }, [])

  const handleToggleModal = (modalState, id) => {
    setOpenModalWindow(modalState)
    setCurrentTaskId(id)
  }

  if (isMonitoringError) {
    return (
      <Typography variant="h3" textAlign="center" my={3}>
        Ошибка
      </Typography>
    )
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h3">Мониторинг</Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DatePicker
          label="Дата поездки"
          value={date}
          onChange={(newValue) => setDate(newValue)}
          slotProps={{
            textField: { size: 'small' },
            actionBar: {
              actions: ['clear'],
            },
          }}
          sx={{ mr: 1.5, bgcolor: 'white', borderRadius: '4px', width: 260 }}
        />
      </LocalizationProvider>

      <Stack flexDirection="row" justifyContent="space-between">
        <Stack
          width="30%"
          p={2}
          spacing={2}
          bgcolor="#FAFAFA50"
          border="1px solid #00000006"
          borderRadius={1}
          mr={2}
        >
          <Typography variant="h5">Транспорт</Typography>

          <Stack flexDirection="row">
            <Stack mr={4}>
              <Typography variant="h5">
                {dailyInfo ? dailyInfo?.online_count : 0}
              </Typography>

              <Typography variant="caption">сейчас на линии</Typography>
            </Stack>

            <Stack>
              <Typography variant="h5">
                {dailyInfo ? dailyInfo?.active_count : 0}
              </Typography>

              <Typography variant="caption">всего на смене</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          width="70%"
          p={2}
          spacing={2}
          bgcolor="#FAFAFA50"
          border="1px solid #00000006"
          borderRadius={1}
        >
          <Typography variant="h5">Задания</Typography>

          <Stack flexDirection="row">
            <Stack mr={4}>
              <Typography variant="h5">
                {monitoringData?.data?.length}
              </Typography>

              <Typography variant="caption">всего заданий</Typography>
            </Stack>

            <Stack mr={4}>
              <Typography variant="h5">{activeTasks?.length}</Typography>

              <Typography variant="caption">запланировано</Typography>
            </Stack>

            <Stack mr={4}>
              <Typography variant="h5">{onProcessTasks?.length}</Typography>

              <Typography variant="caption">в процессе</Typography>
            </Stack>

            <Stack mr={4}>
              <Typography variant="h5">{completedTasks?.length}</Typography>

              <Typography variant="caption">выполнено</Typography>
            </Stack>

            <Stack>
              <Typography variant="h5">{rejectedTasks?.length}</Typography>

              <Typography variant="caption">отменено</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack flexDirection="row">
        <FormControl
          sx={{
            mr: '12px',
            '.MuiOutlinedInput-notchedOutline': { border: '1px solid #FFFFFF' },
          }}
        >
          <InputLabel>Группировать</InputLabel>

          <Select
            value={sortGroupBy}
            label="Группировать"
            onChange={(e) => setSortGroupBy(e.target.value)}
          >
            <MenuItem value="byDriver">По водителям</MenuItem>
            <MenuItem value="byStatus">По статусам</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            mr: '12px',
            '.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #FFFFFF',
            },
          }}
        >
          <InputLabel>Статус</InputLabel>

          <Select
            value={status}
            label="Статусы"
            onChange={(e) => setStatus(e.target.value)}
          >
            {taskStatuses.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {allPointsOptions !== undefined &&
          route_points.map((point) => (
            <Autocomplete
              key={point.label}
              point={point}
              fromPointId={fromPointId}
              toPointId={toPointId}
              setFromPointId={setFromPointId}
              setToPointId={setToPointId}
              allPointsOptions={allPointsOptions}
              allPointsLoading={allPointsLoading}
              allPointsError={allPointsError}
            />
          ))}

        <SearchInput
          minWidth={230}
          onChange={debouncedSearch}
          placeholder="Поиск..."
          sx={{ marginLeft: 'auto', width: '270px' }}
        />
      </Stack>

      {isMonitoringLoading ? (
        <Typography textAlign="center" my={2}>
          Загрузка ...
        </Typography>
      ) : sortGroupBy === 'byStatus' ? (
        <TaskListByStatus
          activeTasks={activeTasks}
          onProcessTasks={onProcessTasks}
          completedTasks={completedTasks}
          rejectedTasks={rejectedTasks}
          handleToggleModal={handleToggleModal}
        />
      ) : (
        <TaskListByDrivers
          drivers={driversWithTasks}
          handleToggleModal={handleToggleModal}
        />
      )}

      {currentTaskId && (
        <TaskModal
          taskId={currentTaskId}
          handleToggleModal={handleToggleModal}
          open={openModalWindow}
        />
      )}
    </Stack>
  )
}

export default Monitoring

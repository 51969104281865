import { Stack, Typography } from '@mui/material'

import { dateTimeFormat } from '../../../utils/dateUtils'
import { taskStatuses } from '../../../enums'

export function Details({ task }) {
  const status = taskStatuses.find((s) => s?.value === task?.status)
  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <Typography variant="h5">Задание</Typography>

        <Stack spacing={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Статус</Typography>

            <Typography
              variant="subtitle2"
              sx={{
                backgroundColor: `${status?.bgcolor}`,
                color: `${status?.color}`,
                padding: '6px 12px',
                borderRadius: '16px',
                display: 'inline',
              }}
            >
              {status?.label}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Откуда</Typography>

            <Typography>{task?.from_point?.name}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Куда</Typography>

            <Typography>{task?.to_point?.name}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Планируемое время</Typography>

            <Typography>
              {dateTimeFormat(task?.action_start_time)} -{' '}
              {dateTimeFormat(task?.action_end_time)}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Фактическое время</Typography>

            <Typography>
              {task?.actual_start_time &&
                task?.actual_end_time &&
                dateTimeFormat(task?.actual_start_time) +
                  ' - ' +
                  dateTimeFormat(task?.actual_end_time)}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Кол-во пассажиров</Typography>

            <Typography>
              {task?.passenger_application_pairs?.length} чел.
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h5">Транспорт</Typography>

        <Stack spacing={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Водитель</Typography>

            <Typography>
              {task?.driver?.surname} {task?.driver?.name[0]}
              {'. '}
              {task?.driver?.middle_name && task?.driver?.middle_name[0] + '.'}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Автомобиль</Typography>

            <Typography>
              {task?.vehicle?.mark?.name} {task?.vehicle?.model?.name}{' '}
              {task?.vehicle?.year}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="gray">Госномер</Typography>

            <Typography>{task?.vehicle?.state_number}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

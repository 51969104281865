import { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'

const useDeleteConfirmDialog = ({ deleteFunc, dialogTitle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deleteId, setDeleteId] = useState('')

  const handleOpenDialog = () => setIsOpen((x) => !x)

  const openDeleteConfirmDialog = (deleteId) => {
    setDeleteId(deleteId)
    setIsOpen(true)
  }

  useEffect(() => {
    !isOpen && setDeleteId('')
  }, [isOpen])

  const handleDelete = () => {
    deleteFunc(deleteId)
    handleOpenDialog()
  }

  const DeleteDialog = (
    <Dialog
      open={isOpen}
      onClose={handleOpenDialog}
      fullWidth={true}
      sx={{
        '& .MuiDialog-paper': {
          boxShadow: 'none',
          p: 4,
        },
      }}
    >
      <DialogTitle variant="h4" textAlign="center">
        Подтвердить удаление
      </DialogTitle>

      <Typography textAlign="center" my={4}>
        {dialogTitle}
      </Typography>

      <DialogActions sx={{ mx: '14px' }}>
        <Button
          fullWidth
          onClick={handleOpenDialog}
          color="secondary"
          sx={{ marginRight: '16px' }}
        >
          Нет
        </Button>

        <Button fullWidth onClick={handleDelete} color="error">
          Да
        </Button>
      </DialogActions>
    </Dialog>
  )

  return {
    DeleteDialog,
    openDeleteConfirmDialog,
  }
}

export default useDeleteConfirmDialog

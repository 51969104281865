import { TableCell, TableRow, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

function DriverItem({ driver, toggleDrawer }) {
  const status =
    driver?.status === 'active'
      ? 'Активный'
      : driver?.status === 'on_vacation'
      ? 'В отпуске'
      : 'Уволен'

  return (
    <TableRow>
      <TableCell>
        {driver?.surname} {driver?.name[0]}.{' '}
      </TableCell>

      <TableCell>
        {driver?.current_vehicle === null ? (
          'Нет машины'
        ) : (
          <>
            <Typography variant="subtitle2">
              {driver?.current_vehicle?.mark.name}{' '}
              {driver?.current_vehicle?.model.name}
            </Typography>

            <Typography variant="body2" sx={{ color: 'gray' }}>
              {driver?.current_vehicle?.year} •{' '}
              {driver?.current_vehicle?.seat_count} мест
            </Typography>
          </>
        )}
      </TableCell>

      <TableCell>8{driver?.phone_number}</TableCell>

      <TableCell>{status}</TableCell>

      <TableCell>
        <EditIcon
          sx={{ cursor: 'pointer' }}
          onClick={toggleDrawer(true, driver)}
        />
      </TableCell>
    </TableRow>
  )
}

export default DriverItem
